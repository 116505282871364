import anime from 'animejs/lib/anime';
import Animation from '../animation';

export default class SlideIn extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    });

    this.initial_transform = this.layer.config.transform
    this.opacity = this.layer.config.opacity ? this.layer.config.opacity / 100 : 1
  }

  name() {
    return `Slide in - ${this.direction()}`;
  }

  from() {
    return 'bottom';
  }

  target() {
    if (!this.container) {
      return `#layer_content_${this.uid}_${this.layer.id}`;
    }
    return `.layer${this.container}_${this.uid}_${this.layer.id}`;
  }

  direction() {
    return this.conf().direction || 'bottom';
  }

  out() {
    return !!this.conf().out;
  }

  static properties() {
    return {
      ...super.properties(),
      out: {
        type: 'Boolean',
        default: true
      },
      direction: {
        type: 'Select',
        default: 'bottom',
        options: [
          'bottom',
          'top',
          'left',
          'right',
        ]
      }
    };
  }

  cleanup() {
    const targetElement = $(`${this.target()}`);
    targetElement.css('transform', this.initial_transform)
    targetElement.css('opacity', this.opacity);
  }

  prepare() {
    const targetElement = $(`${this.target()}`);

    targetElement.css('opacity', '0');

    // Apply the translation transformation based on the animation direction
    if (this.direction() === 'bottom') {
      targetElement.css('transform', `${this.initial_transform} translateY(${this.layer.height}px)`);
    } else if (this.direction() === 'top') {
      targetElement.css('transform', `${this.initial_transform} translateY(-${this.layer.height}px)`);
    } else if (this.direction() === 'left') {
      targetElement.css('transform', `${this.initial_transform} translateX(-${this.layer.width}px)`);
    } else if (this.direction() === 'right') {
      targetElement.css('transform', `${this.initial_transform} translateX(${this.layer.width}px)`);
    }
  }

  static exposed_to() {
    return ['component', 'image', 'logo', 'text', 'text_area', 'video', 'price', 'rectangle', 'circle'];
  }

  static int_name() {
    return 'slide_in';
  }

  translation() {
    if (this.direction() === 'bottom') {
      return { translateY: [this.layer.height, 0] };
    }
    if (this.direction() === 'top') {
      return { translateY: [-this.layer.height, 0] };
    }
    if (this.direction() === 'left') {
      return { translateX: [-this.layer.width, 0] };
    }
    if (this.direction() === 'right') {
      return { translateX: [this.layer.width, 0] };
    }
    return {};
  }

  animation() {
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
    });
    timeline.add({
      ...this.translation(),
      opacity: [0, this.opacity],
      duration: (this.out() ? 150 : 300),
      targets: `${this.target()}`,
      easing: this.easing()
    }, 1);

    if (this.out()) {
      timeline.add({
        translateX: [0, this.layer.width * 0.1],
        opacity: [this.opacity, 0],
        duration: 150,
        targets: `${this.target()}`,
        easing: this.easing(),
      }, `+=${this.duration - 550}`);
    }
    return timeline;
  }
}
