import _ from 'lodash';
import api from '../../api/layers.api'
import {
  UPDATE, CREATE, DELETE, INIT, ACTIVATE,
  DELETE_REMOTE, CREATE_REMOTE, UNDO, UPDATE_LINKED,
} from '../mutation_types'
import {
  ROTATE, TRANSLATE, SCALE, RESIZE, ADD, REMOVE, WARP, PALETTE
} from './mutation_types'
import Layer from './model'
import { TOGGLE_SETTINGS } from '../editor/mutation_types'
import { updateLinkedLayers, getLinkedLayers, autoScaleFontSize } from './util'

let update_buffer = {}
let delete_buffer = {}
const autosave_interval_time = 1250
let autosave_interval_update_id = null
let autosave_interval_delete_id = null

const debounced_on_saves = {}

const debounced_on_save = (layer, action, rootState) => {
  if (rootState.options.on_save) {
    if (!debounced_on_saves[layer.id]) {
      debounced_on_saves[layer.id] = _.debounce((layer, action, rootState) => {
        const variant = rootState.variants
          .list.find((variant) => variant.id === layer.image_canvas_variant_id)
        rootState.options.on_save('layer', action, layer, variant)
      }, 500);
    }

    debounced_on_saves[layer.id](layer, action, rootState);
  }
}

const update_layer_remote = (dispatch, layer, rootState) => {
  if (layer && layer.persisted) {
    update_buffer[layer.id] = layer
    debounced_on_save(layer, 'update', rootState)
  }
  if (autosave_interval_update_id === null) {
    autosave_interval_update_id = setInterval(() => {
      const copied_buffer = { ...update_buffer }
      update_buffer = {}
      if (Object.keys(copied_buffer).length > 0) {
        dispatch('update_batch_remote', copied_buffer)
        clearInterval(autosave_interval_update_id)
        autosave_interval_update_id = null
      }
    }, autosave_interval_time)
  }
}

const find_layer = (state, id) => state.list.find(l => l.id == id) //eslint-disable-line

export default {
  init({ commit, dispatch }, layers) {
    commit(INIT, [...layers])
    dispatch('activate', null)
    // dispatch('order')
  },

  async create_from_list({
    dispatch, commit, getters, rootState
  }, layers) {
    const { active_variant } = getters
    if (layers.length === 0) return //eslint-disable-line

    let parent_layer = layers.find((l) => l.parent_id === null)

    if (rootState.options.autosave) {
      parent_layer = await dispatch('create_remote', Layer.factory({
        ...parent_layer,
        image_canvas_variant_id: rootState.variants.list.find(
          (v) => v.size.name === parent_layer.origin_variant_size.name
        ).id,
        id: undefined,
      }))
      if (rootState.options.on_save) rootState.options.on_save('layer', 'create', parent_layer)
    }

    commit(CREATE, parent_layer)

    const children = layers.filter((l) => l.parent_id)

    for (let layer of children) { //eslint-disable-line
      layer = Layer.factory({
        ...layer,
        parent_id: parent_layer.id,
        image_canvas_variant_id: rootState.variants.list.find(
          (v) => v.size.name === layer.origin_variant_size.name
        ).id,
        id: undefined,
      })

      if (rootState.options.autosave) {
        layer = await dispatch('create_remote', layer) //eslint-disable-line
        if (rootState.options.on_save) rootState.options.on_save('layer', 'create', layer)
      }

      commit(CREATE, layer) //eslint-disable-line
    }

    await dispatch('order')
    const active_layer = layers.find((l) => active_variant.id === l.image_canvas_variant_id)
    setTimeout(() => {
      dispatch('activate', active_layer)
    }, 1)
  },

  async create({
    dispatch, commit, getters, rootState, rootGetters
  }, config = {}) {
    let layer = Layer.factory(config)
    layer = Layer.factory({
      ...config,
      image_canvas_variant_id: getters.active_variant.id,
      id: undefined,
    })

    if (rootState.options.autosave) {
      layer = await dispatch('create_remote', layer)
      if (rootState.options.on_save) rootState.options.on_save('layer', 'create', layer)
    }

    if (rootState.options.studio && rootGetters.canvas_version > 1) {
      // Create a layer for each variant
      for (const variant of getters.variants) { //eslint-disable-line
        if (variant.id !== getters.active_variant.id) {
          let l = Layer.factory({
            ...config,
            image_canvas_variant_id: variant.id,
            id: undefined,
            parent_id: layer.id
          }, variant.size, true)
          if (rootState.options.autosave) {
            l = await dispatch('create_remote', l) //eslint-disable-line
            if (rootState.options.on_save) rootState.options.on_save('layer', 'create', l)
          }
          commit(CREATE, l)
        }
      }
    }
    await commit(CREATE, layer)

    // Activate the layer after a timeout to ensure the layer is created
    // Timeout causes this call to be executed after re-paint of Vue.
    // Not the prettiest solution, but it works.
    setTimeout(() => {
      dispatch('activate', layer)
    }, 1)

    dispatch('order')
    return layer
  },

  async update({
    commit, state, getters, dispatch, rootState
  }, layer) {
    let active_layer = getters.active ? getters.active : { ...layer }
    if (layer && layer.id) {
      commit(UPDATE, { ...layer, dirty: true })
      active_layer = { ...active_layer, ...layer, dirty: true }
      if (rootState.options.studio) {
        const { linked_layers, updated_linked_layers } = updateLinkedLayers(state.list, layer, getters.variants); //eslint-disable-line
        const l = { ...active_layer }
        const args = { layer: l, linked_layers, updated_linked_layers }
        commit(UPDATE_LINKED, args)
        if (rootState.options.autosave) {
          updated_linked_layers.forEach((l) => {
            update_layer_remote(dispatch, l, rootState)
          })
        }
      }
    } else {
      commit(UPDATE, { ...active_layer, ...layer, dirty: true })
      active_layer = { ...active_layer, ...layer, dirty: true }
      if (rootState.options.studio) {
        const { linked_layers, updated_linked_layers } = updateLinkedLayers(state.list, layer, getters.variants); //eslint-disable-line
        const l = { ...active_layer }
        const args = { layer: l, linked_layers, updated_linked_layers }
        commit(UPDATE_LINKED, args)
        if (rootState.options.autosave) {
          updated_linked_layers.forEach((l) => {
            update_layer_remote(dispatch, l, rootState)
          })
        }
      }
    }

    if (rootState.options.autosave) {
      update_layer_remote(dispatch, active_layer, rootState)
    }
  },

  palette({ commit, getters }, palette) {
    commit(PALETTE, { layers: getters.list, palette })
  },

  undo({
    dispatch, commit, rootGetters, rootState
  }, history) {
    if (rootGetters['variants/active'].id !== history.new_value.id) {
      dispatch('variants/activate', {
        id: history.new_value.image_canvas_variant_id
      }, { root: true })
    }
    commit(UNDO, { history, id_map: rootState.maps.layer_id_map })
  },

  async create_remote({
    commit
  }, layer) {
    const result = await api.createLayer(layer)
    commit(CREATE_REMOTE, { old_val: layer, new_val: result })
    return result
  },

  async update_remote({ }, layer) { //eslint-disable-line
    await api.updateLayer(layer, { trigger_callbacks: true })
  },

  async update_batch_remote({ dispatch }, batch) { //eslint-disable-line
    dispatch('emit', { type: 'saving', detail: {} }, { root: true })
    try {
      await api.updateLayers(batch)
    } catch (e) {
      console.error(e)
    }
    dispatch('emit', { type: 'saved', detail: {} }, { root: true })
  },

  async delete_remote({
    commit, rootState
  }, layer) {
    if (rootState.options.on_save) rootState.options.on_save('layer', 'delete', layer)
    commit(DELETE_REMOTE, { old_val: layer, new_val: null })

    // Only add parent layers to delete buffer
    // Child layers will be deleted by the parent cascading
    if (layer.parent_id !== null)
      return //eslint-disable-line

    delete_buffer[layer.id] = layer

    if (autosave_interval_delete_id === null) {
      autosave_interval_delete_id = setInterval(async () => {
        const copied_buffer = { ...delete_buffer }
        delete_buffer = {}
        /* eslint-disable */
        for (const key in copied_buffer) {
          try {
            await api.deleteLayer(copied_buffer[key])
          } catch (e) {
            console.log(e)
          }
        }
        /* eslint-enable */
        clearInterval(autosave_interval_delete_id)
        autosave_interval_delete_id = null
      }, autosave_interval_time)
    }
  },

  async delete({
    dispatch, commit, state, rootState
  }, layer) {
    if (layer.layer_type === 'group') {
      const grouped_layers = state.list.filter((l) => l.config.group_id === layer.id)
      const promises = grouped_layers.map((l) => commit(DELETE, l)).concat(grouped_layers.map((l) => dispatch('delete', l)))
      await Promise.all(promises)
    }
    const linked_layers = getLinkedLayers(state.list, layer)
    await commit(DELETE, layer)
    linked_layers.forEach((l) => commit(DELETE, l))
    await dispatch('order')
    dispatch('activate', null)
    if (rootState.options.autosave) {
      dispatch('delete_remote', layer)
      for (const l of linked_layers) { //eslint-disable-line
        dispatch('delete_remote', l)
      }
    }
  },

  translate({
    commit, dispatch, getters, state
  }, { transform, layer_id = getters.active.id }) {
    const layer = find_layer(state, layer_id)
    commit(TRANSLATE, { transform, layer })
    dispatch('update', find_layer(state, layer_id))
  },

  rotate({
    commit, getters, dispatch, state
  }, { transform, layer_id = getters.active.id }) {
    commit(ROTATE, { layer: find_layer(state, layer_id), transform }) //eslint-disable-line
    dispatch('update', { ...find_layer(state, layer_id), rotated: true }) //eslint-disable-line
  },

  scale({ commit }, { layer, matrix, scale }) {
    commit(SCALE, { layer, matrix, scale })
  },

  resize({
    commit, dispatch, getters, state
  }, {
    width, height, transform, layer_id = getters.active.id, auto_scale = false
  }) {
    const layer = find_layer(state, layer_id)
    let { fontSize } = layer
    if (auto_scale) {
      fontSize = autoScaleFontSize(new Layer(layer), null, layer.layer_type === 'review' ? 2 : 1)
    }
    commit(RESIZE, {
      width, height, transform, layer, fontSize
    })
    dispatch('update', find_layer(state, layer_id))
  },

  warp({ commit, getters }, { transform }) {
    commit(WARP, { layer: getters.active, transform })
  },

  order({
    getters,
    dispatch
  }, layers = getters.list) {
    const non_grouped_layers = layers.filter((l) => !l.config.group_id);
    for (const [index, layer] of non_grouped_layers.entries()) { //eslint-disable-line
      const updated_layer = { ...layer, position: (index + 1) * 10 };
      if (updated_layer && updated_layer.id) {
        dispatch('update', { ...updated_layer, dirty: true })
      } else {
        dispatch('update', { ...getters.active, ...updated_layer, dirty: true })
      }
    }
  },

  add({ commit }, layers) {
    commit(ADD, layers)
  },

  remove({ commit }, layers) {
    commit(REMOVE, layers)
  },

  activate({ commit, rootState }, layer) {
    if (layer) {
      commit(ACTIVATE, layer)
      if (rootState.options.on_layer_select) rootState.options.on_layer_select(layer)
    } else {
      commit(ACTIVATE, null)
      if (rootState.options.on_layer_select) rootState.options.on_layer_select(null)
    }

    if (rootState.options.show_settings && layer) commit(TOGGLE_SETTINGS, false, { root: true })
  },

  async update_visibility({
    state, commit, rootState, dispatch
  }, { layer, mode }) {
    const linked_layers = getLinkedLayers(state.list, layer).filter((l) => l.id !== layer.id)
    const all_layers = [...linked_layers, layer]
    switch (mode) {
      case Layer.VISIBILITY_MODES.ALL_HIDDEN:
        dispatch('activate', null)
        all_layers.forEach((l) => commit(UPDATE, { ...l, visible: false, dirty: true }))
        break
      case Layer.VISIBILITY_MODES.ONLY_THIS_VISIBLE:
        linked_layers.forEach((l) => commit(UPDATE, { ...l, visible: false }))
        commit(UPDATE, { ...layer, visible: true, dirty: true })
        break
      case Layer.VISIBILITY_MODES.ONLY_THIS_HIDE:
        dispatch('activate', null)
        commit(UPDATE, { ...layer, visible: false })
        break
      case Layer.VISIBILITY_MODES.ALL_VISIBLE:
        all_layers.forEach((l) => commit(UPDATE, { ...l, visible: true, dirty: true }))
        break
      default:
        break
    }
    if (rootState.options.autosave) {
      const layers = [
        ...getLinkedLayers(state.list, layer)]

      layers.forEach((l) => {
        update_layer_remote(dispatch, l, rootState)
      })
    }
  }
}
