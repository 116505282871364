<template>
    <component v-bind:is="getComponent(layer)" :layer="layer" :brandkit="brandkit" :samples="samples"
        :on_error="on_error" :active="active" :animation_uid="animation_uid" :disabled="disabled" />
</template>

<script>
const components = {}

const requireComponent = require.context(
    './components',
    false,
    /[\w-]+\.vue$/
)

const requireComponentVideo = require.context(
    './components/video',
    false,
    /[\w-]+\.vue$/
)

const importComponents = (requireContext, prefix = '') => {
    requireContext.keys().forEach(fileName => {
        const componentConfig = requireContext(fileName)
        const componentName = prefix + fileName
            .replace(/^\.\/(.*)\.\w+$/, '$1')
            .replace(/-([a-z])/g, (g) => g[1].toUpperCase())
        components[componentName] = componentConfig.default || componentConfig
    })
}

importComponents(requireComponent)
importComponents(requireComponentVideo, 'video/')

export default {
    props: {
        layer: Object,
        brandkit: Object,
        samples: Object,
        on_error: Function,
        active: Boolean,
        animation_uid: String,
        disabled: Boolean,
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
        getComponent(layer) {
            const componentType = layer.config.component.type
            return components[componentType] || components['list']
        }
    }
}
</script>