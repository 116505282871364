<template>
  <span>
    <div v-on:mousedown="e => activateLayer(e)" :class="{ 'editor__layer--inactive': !active }">
      <div class="canvas__layer-text-container" ref="layer" :data-layer-id="layer.id"
        :class="`${disabled ? '' : 'layer_' + layer.id} layer_container_${animation_uid}_${layer.id} canvas__layer-text-container--${editor_uid}`"
        :style="{
          opacity: layer.config['opacity'] ? layer.config['opacity'] / 100 : 1,
          'align-items': layer.config['align-items'],
          ...layer_constraints,
          zIndex: zIndex,
          transform: transform_str,
          position: 'absolute',
          display: layer.visible ? 'block' : 'none',
        }">
        <div :class="`layer_${animation_uid}_${layer.id}`" :style="{
          display: 'flex',
          overflow: overflow,
          'border-radius': layer.config['border_radius'] + 'px',
          backgroundColor: background,
          ...layer_constraints,
          ...layer.styles(),
        }">
          <div style="display: flex;" :id="`layer_content_${animation_uid}_${layer.id}`">
            <component v-bind:is="determine_component(layer)" :layer="layer" :brandkit="brandkit" :samples="samples"
              :grouped_layers="grouped_layers" :on_layer_select="selectLayer" :activeLayer="activeLayer"
              :on_error="on_error" :active="active" :animation_uid="animation_uid" :disabled="disabled"></component>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2';
import { mustache } from '../lib/parsers'
import Teleport from 'vue2-teleport';
import { getComponent } from './helpers'
import { mapState } from 'vuex'

export default {
  components: { Teleport },
  mixins: [clickaway],
  data() {
    return {
      original_text: '',
      visible: true,
      prepare_for_undo: false,
      x: this.$props.layer.x,
      y: this.$props.layer.y,
      matrix: this.$props.layer.matrix,
      transform_str: this.transform(this.layer),
      width: this.$props.layer.width,
      height: this.$props.layer.height,
    }
  },
  props: ['layer', 'layers', 'layerIndex', 'active', 'activeLayer', 'samples', 'render_only', 'on_error', 'brandkit',
    'constraint', 'onLayerDelete', 'zIndex', 'activeVariant', 'canvas_size', 'editor_uid',
    'onLayerSelect', 'onLayerUpdate', 'disabled', 'segments', 'zoom', 'animation_uid'],
  watch: {
    layer: {
      immediate: true,
      deep: true,
      handler(new_layer, old_layer) {
        this.transform_str = this.transform(new_layer)
        this.width = new_layer.width
        this.height = new_layer.height
      }
    },
  },
  computed: {
    ...mapState({
      is_resizing: state => {
        if (state && state.layers) {
          return state.layers.is_resizing;
        }
        return false;
      }
    }),
    display() {
      if (this.layer.layer_type === 'component')
        return 'block'
      return 'flex'
    },
    grouped_layers() {
      return this.layers.filter(l => l.config.group_id === this.layer.id)
    },
    overflow() {
      if (this.layer.layer_type === 'review')
        return 'visible'
      return 'hidden'
    },
    is_disabled() {
      return this.disabled
    },
    layer_constraints() {
      if (this.is_resizing) {
        return {
          width: this.width + 'px',
          height: this.height + 'px',
        }
      }

      if (this.layer.config.constraints_enabled) {
        return {
          'min-width': this.layer.config.constraints_min_width + 'px',
          'max-width': this.layer.config.constraints_max_width + 'px',
          'min-height': this.layer.config.constraints_min_height + 'px',
          'max-height': this.layer.config.constraints_max_height + 'px',
        };
      } else if (this.layer.layer_type === 'text_area' || this.layer.layer_type === 'component') {
        return {
          width: this.width + 'px',
          'min-height': this.height + 'px',
        };
      } else {
        return {
          width: this.width + 'px',
          height: this.layer.height + 'px',
        };
      }
    },

    font() {
      return this.layer.font
    },
    background() {
      if (this.layer.show_background()) {
        if (mustache.detect(this.layer.background)) {
          return mustache.fill_in(this.layer.background, this.samples.data[this.samples.indice])
        }
        if (this.layer.background && this.layer.background.length === 9)
          return this.layer.background.slice(0, -2)

        return this.layer.background
      } else {
        return 'transparent'
      }
    }
  },
  mounted() {
    this.original_text = this.layer.text
    this.x = this.layer.x
    this.y = this.layer.y
    this.width = this.layer.width
    this.height = this.layer.height
  },
  methods: {
    determine_component(layer) {
      return getComponent(layer)
    },
    transform(layer) {
      if (layer.config.transform)
        return layer.config.transform

      let transform = ''
      if (layer.rotation) {
        transform += `rotate(${layer.rotation}deg)`
      }
      if (layer.translation) {
        transform += `translate(${layer.translation[0]}px, ${layer.translation[1]}px)`
      }
      return transform
    },
    selectLayer(e, dbclick = false, layer = this.layer) {
      if (this.$props.onLayerSelect && !this.is_disabled) {
        this.$props.onLayerSelect(layer, dbclick)
      }
    },
    activateLayer(event) {
      if (this.$parent && this.$parent.$parent && this.$parent.$parent.$parent && this.$parent.$parent.$parent.$parent && this.$parent.$parent.$parent.$parent.$refs && this.$parent.$parent.$parent.$parent.$refs.moveable) {
        const moveable = this.$parent.$parent.$parent.$parent.$refs.moveable
        if (moveable) {
          moveable.activateLayerDrag(event, this.layer)
        }
      }
      this.selectLayer(event, false, this.layer)
    },
    updateLayer(newRect) {
      if (this.$props.onLayerUpdate)
        this.$props.onLayerUpdate({
          ...this.layer,
          ...newRect
        })
    },
    store: () => window.vuex_store,
  }
}

</script>