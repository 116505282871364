<template>
  <div class="studio__playbar">
    <div class="studio__playbar__header" v-if="is_video && ui_edit_state !== 'ANIMATING'">
      <div v-if="currentActiveSound === 'None'">
        <div class="chip" v-on:click="tab = 'music';">
          <i class="fa-regular fa-music"></i>
          <span>Select song</span>
        </div>
      </div>
      <div v-else>
        <div class="chip chip--active" v-on:click="tab = 'music';">
          <i class="fa-regular fa-music"></i>
          <span>{{ currentActiveSound }}</span>
          <i @click="resetSound" class="fa-regular fa-xmark"></i>
        </div>
      </div>

      <div class="studio__scene-powered-by">
        <img src="/pixabay.svg">
      </div>

      <button @click="toggle_animating_mode" class="button__toggle">
        <i class="fa-sharp fa-regular fa-circle-play"></i>
        Animate
      </button>

    </div>

    <div class="studio__playbar__header" v-else>
      <button @click="toggle_animating_mode" v-if="is_video" class="button__toggle">
        <i class="fa-regular fa-xmark"></i>
        Close animate
      </button>
    </div>

    <div class="studio__playbar__body">
      <div v-if="is_video" class="studio__playbar__player" v-on:click="playpause">
        <span v-if="briefing_canvasses.length > 0">
          <i v-if="player_paused" class="fa-solid fa-play"></i>
          <i v-else class="fa-solid fa-pause"></i>
        </span>
        <div v-else>
          <i v-if="player_paused" class="fa-solid fa-play"></i>
          <i v-else class="fa-solid fa-pause"></i>
        </div>
        <div class="body-small-regular">
          <span>{{ current_play_time }} / {{ (duration / 1000).toFixed(2) }}s</span>
        </div>
      </div>

      <div class="studio__scenes-holder" v-if="ui_edit_state !== 'ANIMATING'">
        <draggable :swapThreshold="0.5" @start="drag = true" @end="drag = false" v-model="l_briefing_canvasses"
          group="scenes" @change="e => update_order(e)">
          <transition-group name="fade" tag="div" style="display: flex;">
            <Scene :disabled="false" :scene="bc" v-for="bc in briefing_canvasses" :key="bc.id"
              :ref="`scene_${bc.id}`" />
          </transition-group>
        </draggable>

        <div class="studio__scene-progress-indicator" v-if="is_video" :style="{ 'left': progress + '%' }">
        </div>
      </div>

      <div class="studio__scene-card studio__scene-card--new" v-if="ui_edit_state !== 'ANIMATING'"
        :class="{ 'studio__scene-card--active': show_template_picker }" v-on:click="e => add_scene()">
        <i class="fa-solid fa-plus"></i>
      </div>

      <div v-if="ui_edit_state === 'ANIMATING'" style="display: flex; gap: 8px; width: 100%;">
        <div class="studio__scenes-holder">
          <Scene :scene="active_scene" :disabled="true" />
        </div>

        <button :disabled="!has_previous_scene" class="button__toggle__backplated" @click="to_previous_scene">
          <i class="fa-regular fa-chevron-left"></i>
        </button>

        <AnimationTimeline :layers="all_active_scene_size_layers" :show_variants="true" :active_layer="active_layer"
          :on_layer_update="on_layer_update" :video="{
            enabled: true,
            controller: get_controller(active_scene.canvas.id)
          }" :key="active_size" :on_animation_select="animation => set_active_layer(animation)" />
        <button :disabled="!has_next_scene" class="button__toggle__backplated" @click="to_next_scene">
          <i class="fa-regular fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Scene from './scene.vue'
import draggable from 'vuedraggable'
import AnimationTimeline from '../../image-editor/animation_timeline.vue'
import { mapState, mapGetters } from 'vuex'
export default {
  components: { Scene, draggable, AnimationTimeline },
  data() {
    return {
      l_briefing_canvasses: [],
      animation_mode: false,
    }
  },
  mounted() {
    document.addEventListener('keyup', this.handle_spacebar)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handle_spacebar)
  },
  watch: {
    briefing_canvasses: {
      handler: function () {
        this.l_briefing_canvasses = this.briefing_canvasses
      },
      deep: true
    }
  },
  methods: {
    set_active_layer(animation) {
      this.editor_ref.setActiveLayer(animation.layer)
    },
    get_controller(canvas_id) {
      return this.$store.state.animation_controller_map[canvas_id]
    },
    add_scene() {
      if (this.$store.state.briefing.version > 1) {
        this.$store.dispatch('add_design', { id: null })
      } else {
        this.show_template_picker = true
      }
    },
    on_layer_update(layer) {
      this.$store.commit('UPDATE_BRIEFING_CANVAS_LAYER', { layer, briefing_canvas: this.active_scene, size: { name: this.$store.state.size } })
    },
    playpause() {
      if (this.briefing_canvasses.length == 0) return
      this.$store.commit('SCENE_PLAYER_PAUSED', !this.$store.state.player_paused)
      this.$store.dispatch('SET_SHOW_TEMPLATE_PICKER', false)
    },
    update_order(e) {
      let obj = e.moved
      this.$store.dispatch("SCENE_REORDER", {
        old_index: obj.oldIndex,
        new_index: obj.newIndex,
        element: obj
      })
    },
    toggle_animating_mode() {
      if (this.$store.state.ui_edit_state === 'ANIMATING') {
        this.$store.commit('SET_UI_EDIT_STATE', 'DEFAULT')
      } else {
        this.$store.commit('SET_UI_EDIT_STATE', 'ANIMATING')
      }
    },
    resetSound() {
      this.$store.dispatch("update_briefing", { ...this.$store.state.briefing, sound: 'None' });
    },
    update_sound(e) {
      this.$store.dispatch("update_briefing", { ...this.$store.state.briefing, sound: e.target.value })
    },
    to_previous_scene() {
      this.$store.dispatch('previous_scene')
    },
    to_next_scene() {
      this.$store.dispatch('next_scene')
    },
    get_scene_index(scene) {
      return this.briefing_canvasses.findIndex(bc => bc.id === scene.id)
    },
    handle_spacebar(e) {
      e.preventDefault();
      let active_element_tag = document.activeElement.tagName.toLowerCase()
      if (active_element_tag !== 'input' && active_element_tag !== 'textarea') {
        if (e.code === 'Space') {
          this.playpause()
        }
      }
    }
  },
  computed: {
    current_play_time() {
      return (this.duration / 1000 * (this.progress / 100)).toFixed(2)
    },
    ...mapGetters({
      all_active_scene_layers: 'all_active_scene_layers',
      all_active_scene_size_layers: 'all_active_scene_size_layers',
      all_active_scene_variable_layers: 'all_active_scene_variable_layers',
      has_next_scene: 'has_next_scene',
      has_previous_scene: 'has_previous_scene',
    }),
    ...mapState({
      editor_ref: state => state.editor_ref,
      event_target: state => state.event_target,
      sounds: state => state.sounds,
      brandkit: state => state.brandkit,
      progress: state => state.player_progress,
      duration: state => state.player_duration,
      player_paused: state => state.player_paused,
      briefing: state => state.briefing,
      active_size: state => state.size,
      active_scene: state => state.active_scene,
      ui_edit_state: state => state.ui_edit_state,
      briefing_canvasses: state => state.briefing.canvasses,
      active_layer: state => state.active_layer,
    }),
    currentActiveSound() {
      return this.briefing.sound;
    },
    is_video() {
      return this.briefing.type !== 'ImageBriefing'
    },
    briefing_canvasses() {
      return this.$store.getters.root_scenes
    },
    show_template_picker: {
      get() {
        return this.$store.state.template_picker.show
      },
      set(value) {
        this.$store.dispatch('SET_SHOW_TEMPLATE_PICKER', value)
      }
    },
    tab: {
      get() {
        return this.$store.state.tab
      },
      set(value) {
        this.$store.commit('SET_TAB', value)
      }
    },
  }
}
</script>
