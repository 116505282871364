<template>
  <div :style="{
    width: layer.width + 'px',
    height: layer.height + 'px',
    position: 'relative',
    backgroundColor: 'none'
  }">
    <!-- Grid Container -->
    <div :style="gridContainerStyle">
      <!-- Render images dynamically -->
      <div v-for="(img, index) in images" :key="index" :style="{
        ...imageContainerStyle,
        backgroundImage: 'url(' + cleanSource(img) + ')',
        backgroundPosition: getBackgroundPosition(img)
      }">
      </div>
    </div>
  </div>
</template>

<script>
import FontMixin from '../mixins/font';

export default {
  props: ['layer', 'animation_uid', 'scale'],
  mixins: [FontMixin],
  computed: {
    componentConfig() {
      return this.layer.config.image_grid;
    },
    images() {
      // Dynamisch genereren van de array met afbeeldingen
      const config = this.layer.config.image_grid;
      return Object.keys(config.images).slice(0, this.componentConfig.number_of_images).map(key => {
        return config.images[key];
      });

    },
    gridContainerStyle() {
      const direction = this.componentConfig?.direction || 'row';
      const theGap = this.componentConfig?.gap + 'px';
      return {
        display: 'flex',
        flexDirection: direction,
        flexWrap: 'wrap',
        gap: theGap, // Voeg gap hier direct toe als string
        width: '100%',
        height: '100%',
        boxSizing: 'border-box', // Houd rekening met eventuele padding
      };
    },
    imageContainerStyle() {
      const backgroundSize = this.componentConfig.scale || 'cover'; // Standaard naar 'cover'
      const bgColor = this.componentConfig.show_bg ? (this.componentConfig.color_1 || '#111111') : 'transparent'; // Achtergrondkleur alleen als show_bg true is
      return {
        flexGrow: 1, // Zorgt ervoor dat de items de beschikbare ruimte vullen
        flexBasis: '0', // Standaard basisgrootte instellen op 0
        backgroundSize: backgroundSize, // Stel de achtergrondgrootte in op basis van de scale property
        backgroundRepeat: 'no-repeat', // Geen herhaling van de achtergrond
        backgroundPosition: 'center', // Achtergrond gecentreerd
        backgroundColor: bgColor, // Toepassen van de achtergrondkleur
        width: '100%',
        height: '100%',
        borderRadius: this.componentConfig.border_radius + 'px',
        overflow: 'hidden',
      };
    }
  },
  methods: {
    cleanSource(source) {
      if (source) {
        try {
          const url = new URL(source);
          const focus_x = url.searchParams.get('focus_x');
          const focus_y = url.searchParams.get('focus_y');
          if (focus_x && focus_y && url.searchParams.toString() === `focus_x=${focus_x}&focus_y=${focus_y}`) {
            return url.origin + url.pathname;
          }
          return source.split('?').shift();
        } catch {
          return source;
        }
      }
      return source
    },
    getFocusPoints(image) {
      try {
        const url = new URL(image);
        const focusX = parseFloat(url.searchParams.get('focus_x') || 0);
        const focusY = parseFloat(url.searchParams.get('focus_y') || 0);
        return { x: focusX, y: focusY };
      } catch {
        return { x: 0, y: 0 };
      }
    },
    getBackgroundPosition(image) {
      // Controleer of de scale property op 'contain' staat
      if (this.componentConfig.scale === 'contain') {
        return 'center'; // Altijd gecentreerd bij contain
      }

      // Gebruik focuspunten als scale niet 'contain' is
      const focus = this.getFocusPoints(image);
      let y = Math.abs(focus.y) / 2 * 100;
      let x = Math.abs(focus.x) / 2 * 100;

      if (focus.y < 0) {
        y += 50;
      } else {
        y = 50 - Math.abs(focus.y) * 50;
      }

      if (focus.x > 0) {
        x += 50;
      } else {
        x = 50 - Math.abs(focus.x) * 50;
      }

      return `${x}% ${y}%`;
    },
  }
};
</script>