<template>
    <div ref="cubeContainer" style="overflow: hidden;" :style="`width: ${layer.width}px; height: ${layer.height}px`">
    </div>
</template>

<script>
import * as THREE from 'three';

export default {
    name: 'CubeSpinner',
    data() {
        return {
            camera: null,
            renderer: null,
            scene: null,
            cube: null,
            animationId: null
        };
    },
    props: {
        layer: {
            type: Object,
            required: true
        },
        samples: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        on_error: {
            type: Function,
            required: false,
            default: () => { }
        },
        brandkit: {
            type: Object,
            required: false,
            default: () => { }
        },
        animation_uid: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.initThreeJS();
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        this.cleanupThreeJS();
    },
    computed: {
        imagePath() {
            return this.layer.config.image_url || 'https://placehold.co/600x400';
        }
    },
    watch: {
        layer: {
            handler() {
                this.updateSize();
            },
            deep: true
        }
    },
    methods: {
        initThreeJS() {
            // Create scene
            const scene = new THREE.Scene();
            this.scene = scene;

            // Create camera
            const camera = new THREE.PerspectiveCamera(
                75,
                this.$refs.cubeContainer.clientWidth / this.$refs.cubeContainer.clientHeight,
                0.1,
                1000
            );
            camera.position.z = 1.5;
            this.camera = camera;

            // Create renderer
            const renderer = new THREE.WebGLRenderer({ alpha: true });
            renderer.setSize(this.$refs.cubeContainer.clientWidth, this.$refs.cubeContainer.clientHeight);
            renderer.setClearColor(0x000000, 0);
            this.$refs.cubeContainer.appendChild(renderer.domElement);
            this.renderer = renderer;

            // Handle WebGL context loss and restoration
            renderer.domElement.addEventListener('webglcontextlost', this.onContextLost, false);
            renderer.domElement.addEventListener('webglcontextrestored', this.onContextRestored, false);

            // Load the texture and create the cube
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load(
                this.imagePath,
                (texture) => {
                    const material = new THREE.MeshBasicMaterial({ map: texture });
                    const geometry = new THREE.BoxGeometry();
                    const cube = new THREE.Mesh(geometry, material);
                    scene.add(cube);
                    this.cube = cube;

                    // Start the animation loop
                    this.startAnimation();
                },
                undefined,
                this.on_error
            );
        },

        startAnimation() {
            const animate = () => {
                this.animationId = requestAnimationFrame(animate);

                // Rotate the cube
                if (this.cube) {
                    this.cube.rotation.x += 0.01;
                    this.cube.rotation.y += 0.01;
                }

                // Render the scene
                if (this.renderer && this.scene && this.camera) {
                    this.renderer.render(this.scene, this.camera);
                }
            };

            animate();
        },

        cleanupThreeJS() {
            try {
                if (this.animationId) {
                    cancelAnimationFrame(this.animationId);
                }
                if (this.renderer) {
                    this.renderer.dispose();
                }
                if (this.cube) {
                    this.cube.geometry.dispose();
                    this.cube.material.dispose();
                }
            } catch (e) {
                console.error('Error cleaning up ThreeJS', e);
            }
        },

        onContextLost(event) {
            event.preventDefault();
            console.warn('WebGL context lost');
            this.cleanupThreeJS();
        },

        onContextRestored() {
            console.log('WebGL context restored');
            this.cleanupThreeJS();
            this.initThreeJS();
        },

        onResize() {
            this.updateSize();
        },

        updateSize() {
            const width = this.$refs.cubeContainer.clientWidth;
            const height = this.$refs.cubeContainer.clientHeight;
            if (this.camera) {
                this.camera.aspect = width / height;
                this.camera.updateProjectionMatrix();
            }
            if (this.renderer) {
                this.renderer.setSize(width, height);
            }
        }
    }
};
</script>