import Vue from 'vue/dist/vue.js' // eslint-disable-line
import VModal from 'vue-js-modal'
import Vuex from 'vuex'
import Canvas from './image-editor/canvas.vue'
import Previewer from './image-editor/previewer.vue'
import CanvasThumb from './image-editor/canvas_thumb.vue'
import ImageEditor from './image-editor/editor.vue'

Vue.use(VModal)

Vue.use(Vuex)

const store = new Vuex.Store({})

function loadCanvas(data = {}) {
  const imageEditor = document.getElementsByTagName('image-editor')[0]
  if (imageEditor != null) {
    const app = new Vue({ // eslint-disable-line
      el: 'image-editor',
      store,
      components: { 'image-editor': ImageEditor },
    });
  }

  $('canvas-thumb').each((index, el) => {
    const renderComponent = { tag: 'canvas-thumb', component: CanvasThumb }
    const app = new Vue({ // eslint-disable-line
      el,
      store,
      components: { [renderComponent.tag]: renderComponent.component },
      data
    })
  })

  $('editor-canvas').each((index, el) => {
    const renderComponent = { tag: 'editor-canvas', component: Canvas }
    const app = new Vue({ // eslint-disable-line
      el,
      store,
      components: { [renderComponent.tag]: renderComponent.component },
      data
    })
    window.canvasses.push(app)
  })

  $('editor-previewer').each((index, el) => {
    const renderComponent = { tag: 'editor-previewer', component: Previewer }
    const app = new Vue({ // eslint-disable-line
      el,
      store,
      components: { [renderComponent.tag]: renderComponent.component },
      data
    })
  })
}
window.canvasses = []
window.load_image_preview = loadCanvas

export default loadCanvas
