import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class StockerRepeater extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Sticker repeater'
  }

  labels() {
    return ['sticker']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'textArea',
          name: 'Callout',
          hide_label: true,
          key: 'root.text',
        },
        [
          {
            type: 'color',
            name: 'Text',
            key: 'root.color',
          },
          {
            type: 'color',
            name: 'Icon',
            key: 'icon_color',
          },
          {
            type: 'color',
            name: 'Background color',
            key: 'root.background',
          },
        ],
        {
          type: 'buttonGroup',
          name: 'Icon type',
          key: 'icon_type',
          options: [
            {
              type: 'icon',
              name: 'Check',
              icon: 'fa-solid fa-minus',
              value: 'fa-solid fa-minus',
            },
            {
              type: 'icon',
              name: 'Check circle',
              icon: 'fa-solid fa-circle',
              value: 'fa-solid fa-circle',
            },
            {
              type: 'icon',
              name: 'Check circle',
              icon: 'fa-solid fa-pipe',
              value: 'fa-solid fa-pipe',
            },
            {
              type: 'text',
              name: 'None',
              value: 'none',
            },
          ]
        },
        {
          type: 'slider',
          name: 'Distance',
          key: 'gap',
          min: 0,
          max: 100,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          color: 'black',
          background: 'white',
          config: {
            ...baseConfig.config,
            sticker_repeater: {
              ...baseConfig.config.sticker_repeater,
              align: 'left',
              text_color: 'black',
              gap: 20,
              icon_type: 'fa-solid fa-pipe',
              icon_color: 'grey',
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'white',
      align: 'center',
      text: 'Sale 25% OFF',
      width: 1200,
      height: 50,
      fontSize: 69,
      background: '#FF4B4B',
      ...this.opts,
      config: {
        v_align: 'center',
        show_background: true,
        component: {
          type: 'sticker_repeater',
          name: 'Sticker repeater',
        },
        sticker_repeater: {
          align: 'left',
          text_color: 'white',
          gap: 20,
          icon_type: 'fa-solid fa-circle',
          icon_color: '#FFEE12',
        }
      }
    }
  }

  config() {
    return {
      background: 'white',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
