<template>
    <div :style="{ color: layer.color, width: layer.width + 'px', position: 'relative' }">
    
        <div
            style="
                display: flex;
                flex-direction: column;
                z-index: 0;
                border-radius: 20px;
                padding: 20px 20px 20px 30px;
                background: white;
                align-items: flex-start;
                overflow: hidden;
                gap: 10px;"
            >

            <div
                style="
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;"
                :style="{ gap: top_text ? '10px' : '0px' }"
            >
                <span
                    style="
                        width: 100%;
                        text-wrap: wrap;
                        color: #000000;
                        font-weight: bold"
                    :style="{ 
                        fontSize: layer.fontSize * 0.8 + 'px',
                        fontFamily: font,
                    }">
                    {{ top_text }}
                </span>
                <div style="display: flex; gap: 2px;" :style="{fontSize: layer.fontSize * 0.8 + 'px', color: layer.config.review_card_2.bg_color}">
                    <i class="fa-sharp fa-solid fa-star-sharp"></i>
                    <i class="fa-sharp fa-solid fa-star-sharp"></i>
                    <i class="fa-sharp fa-solid fa-star-sharp"></i>
                    <i class="fa-sharp fa-solid fa-star-sharp"></i>
                    <i class="fa-sharp fa-solid fa-star-sharp"></i>
                </div>
            </div>
            <span
                class=""
                style="
                    width: 100%;
                    color: #151515;
                    text-wrap: wrap;"
                :style="{ 
                    fontSize: layer.fontSize * 0.8 + 'px',
                    fontFamily: font,
                }">
                {{ layer.text }}
            </span>
        </div>

    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    components: {},
    mixins: [FontMixin],
    computed: {
        component_name() {
            return this.layer.config.component.type
        },
        bgColor() {
            return this.layer.config[this.component_name].bg_color || 'white'
        },
        top_text() {
            return this.layer.config.review_card_2.top_text
        },
        image() {
            return this.layer.config.review_card_2.image
        },
    },
    methods: {
        parse(text) {
            return highlight.highlight(text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color)
        },
        isImage(icon) {
            try {
                const url = new URL(icon);
                return /\.(jpeg|jpg|gif|png|svg|webp)$/.test(url.pathname);
            } catch {
                return false; // Geen geldige URL
            }
        },
    }
}
</script>