<template>
    <GeneratedComponentConfig :mediaFolder="mediaFolder" :layer="layer" :brandkit="brandkit" :samples="samples"
        :onUpdate="onUpdate" :palette="palette" :fonts="fonts" />
</template>

<script>
import GeneratedComponentConfig from './components/dynamic.vue'
export default {
    components: {
        GeneratedComponentConfig
    },
    props: {
        layer: Object,
        fonts: Array,
        mediaFolder: String,
        onUpdate: Function,
        inputName: String,
        brandkit: Object,
        samples: Object,
        mediaFolder: String,
        palette: {
            type: Array,
            default: () => ([])
        },
        nightswatch: Boolean,
        is_studio: Boolean,
        version: Number,
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>