import _ from 'lodash'
import {
  PROCESSING, ADD_BATCH_MESSAGE, ADD_BRIEFING_FOLDER, REMBG,
  INIT_TEMPLATES, ADD_ASSET, ADD_DESIGN, INIT_FOLDERS, SET_SHOW_TEMPLATE_PICKER,
  DELETE_DESIGN, DELETE_ASSET, INIT, UPDATE_ASSET, SET_TAB, SET_TEMPLATE_TEMPLATE_PICKER,
  SET_BATCH, UPDATE_BRIEFING, REFRESH_PREVIEW_DATA, REFRESH_BRIEFING_CANVASSES,
  SET_PREVIEW_TEMPLATE_PICKER, TOGGLE_VARIANT, SET_VARIANTS_TEMPLATE_PICKER, ACTIVATE_SCENE,
  UPDATE_SCENE_FEED_ROW, DELETE_FEED_ROW, SCENE_PLAYER_DURATION, SET_UI_STATE,
  ADD_FEED_ROW, UPDATE_BRIEFING_CANVAS, SCENE_PLAYER_PROGRESS, DELETE_TEMPLATE,
  SCENE_PLAYER_PAUSED, GET_BRIEFING_CANVAS, UPDATE_SCENE_FEED, SET_SIZE, SET_SIZE_HOVER,
  SET_SHOW_SIZE_SELECTOR, UPDATE_SCENE_DURATION, SET_CREDITS, FINISH_INITIAL_LOAD,
  SET_SCENE_CONTEXT, UPDATE_BRIEFING_CANVAS_LAYER, ADD_BRIEFING_CANVAS_LAYER,
  DELETE_BRIEFING_CANVAS_LAYER, SET_GLOBAL_ZOOM, SET_EDITOR_REF, SET_ANIMATION_CONTROLLER_MAP,
  SET_UI_EDIT_STATE, SET_ACTIVE_LAYER, TOGGLE_SAFE_MARGINS
} from './mutation_types'

import { updateLinkedLayers } from '../../image-editor/store/modules/layers/util'

export default {
  [INIT](state, {
    briefing, brandkit, version, options, fonts
  }) {
    state.fonts = fonts
    state.briefing = briefing
    state.credits = options ? options.initial_credits : 0
    state.options = options
    state.available_sizes = briefing.available_sizes.filter((s) => s.name !== 'sizeless')
    state.rembg = briefing.rembg
    state.brandkit = brandkit
    state.active_feed = briefing.feed
    state.version = version
    state.size = briefing.sizes[1] || 'facebook_catalog'
  },
  [TOGGLE_SAFE_MARGINS](state) {
    state.show_safe_margins = !state.show_safe_margins
  },
  [SET_EDITOR_REF](state, editor) {
    state.editor_ref = editor
  },
  [SET_GLOBAL_ZOOM](state, zoom) {
    state.global_zoom = zoom
  },
  [SET_ACTIVE_LAYER](state, layer) {
    state.active_layer = layer
  },
  [SET_SCENE_CONTEXT](state, scene) {
    state.scene_context = scene
  },
  [SET_CREDITS](state, credits) {
    state.credits = credits
  },
  [FINISH_INITIAL_LOAD](state, bool = false) {
    state.initializing = bool
  },
  [SET_ANIMATION_CONTROLLER_MAP](state, { canvas_id, controller }) {
    state.animation_controller_map[canvas_id] = controller
  },
  // Currently this works wonky when updating scenes using the editor in studio
  // Each editor has its own data context and vuex store which are populated by briefing canvas data
  // When a user updates a layer only the context within the editor is updated
  // This update is never propgated to the studio store which mean it will be out of sync
  // Swapping between sizes makes it seem as if updates haven't saved (even though they have)
  // As it's basically resetting to old state
  [SET_SIZE](state, size) {
    state.size = size
    if (state.briefing_canvasses.length > 0 && Object.keys(state.active_scene).length === 0) {
      const active_scene = state.briefing_canvasses[0]
      state.active_scene = active_scene
    }
  },
  [SET_SHOW_SIZE_SELECTOR](state, show) {
    state.show_size_selector = show
  },
  [SET_SIZE_HOVER](state, size) {
    state.size_hover = size
  },
  [SET_TAB](state, tab) {
    state.tab = tab
  },
  [SET_UI_STATE](state, ui_state) {
    state.ui_state = ui_state
  },
  [SET_UI_EDIT_STATE](state, ui_edit_state) {
    state.ui_edit_state = ui_edit_state
  },
  [GET_BRIEFING_CANVAS](state, briefing_canvas) {
    state.briefing_canvasses = state.briefing_canvasses.map((bc) => {
      if (bc.id === briefing_canvas.id) {
        return briefing_canvas
      }
      return bc
    })
  },
  [SCENE_PLAYER_PROGRESS](state, progress) {
    state.player_progress = progress
  },
  [SCENE_PLAYER_DURATION](state, duration) {
    state.player_duration = duration
  },
  [SCENE_PLAYER_PAUSED](state, paused) {
    state.player_paused = paused
  },
  [ACTIVATE_SCENE](state, scene) {
    // if (scene === null) return
    state.active_scene = { ...scene }
  },
  [UPDATE_SCENE_DURATION](state, scene) {
    if (scene.id === state.active_scene.id) {
      state.active_scene.min_scene_duration = scene.min_scene_duration
    }
    const indice = state.briefing_canvasses.findIndex((bc) => bc.id === scene.id)
    state.briefing_canvasses[indice].min_scene_duration = scene.min_scene_duration
  },
  [SET_TEMPLATE_TEMPLATE_PICKER](state, template) {
    state.template_picker.canvas = template
  },
  [SET_SHOW_TEMPLATE_PICKER](state, show) {
    state.template_picker.show = show
  },
  [SET_PREVIEW_TEMPLATE_PICKER](state, feeds) {
    state.template_picker.preview_feeds = feeds
  },
  [SET_VARIANTS_TEMPLATE_PICKER](state, variants) {
    state.template_picker.variants = variants
  },
  [ADD_FEED_ROW](state, { scene, row }) {
    row = { data: row }
    state.briefing_canvasses = state.briefing_canvasses.map((bc) => {
      if (bc.id === scene.id || bc.parent_id === scene.id) {
        bc.feed.data.push(row)
        return {
          ...bc,
        }
      }
      return bc
    })
  },
  [DELETE_FEED_ROW](state, { scene, row }) {
    state.briefing_canvasses = state.briefing_canvasses.map((bc) => {
      if (bc.id === scene.id || bc.parent_id === scene.id) {
        bc.feed.data = bc.feed.data.filter((r) => r.data.adflow_row_id !== row.data.adflow_row_id)
        return {
          ...bc,
        }
      }
      return bc
    })
  },
  [UPDATE_SCENE_FEED](state, { feed }) {
    // state.active_scene.canvas.feed.data = [...feed]
    state.briefing_canvasses = state.briefing_canvasses.map((bc) => {
      if (bc.id === state.scene_context.id) {
        bc.canvas.feed = {
          data: [...feed]
        }
      }
      return bc
    })
    state.scene_context.canvas.feed = {
      data: [...feed]
    }
  },
  [UPDATE_SCENE_FEED_ROW](state, {
    element, scene, key, value
  }) {
    element = element.data
    state.briefing_canvasses = state.briefing_canvasses.map((bc) => {
      if (bc.id === scene.id || bc.parent_id === scene.id) {
        bc.feed.data = bc.feed.data.map((row) => {
          if (row.data.adflow_row_id === element.adflow_row_id) {
            row.data[key] = value
          }
          return row
        })
        return {
          ...bc,
        }
      }
      return bc
    })
  },
  [TOGGLE_VARIANT](state, { indice, feed }) {
    state.template_picker.variants = [{ indice, feed }]
  },
  [DELETE_BRIEFING_CANVAS_LAYER](state, { layer, briefing_canvas }) {
    state.briefing_canvasses = _.cloneDeep(state.briefing_canvasses).map((bc) => {
      if (bc.id === briefing_canvas.id) {
        bc.canvas.variants = bc.canvas.variants.map((v) => {
          if (v.id === layer.image_canvas_variant_id) {
            v.layers = v.layers.filter((l) => l.id !== layer.id);
          }
          return v;
        });
      }
      return bc;
    });
    state.active_scene = state.briefing_canvasses.find((bc) => bc.id === state.active_scene.id);
  },
  [ADD_BRIEFING_CANVAS_LAYER](state, { layer, briefing_canvas }) {
    state.briefing_canvasses = _.cloneDeep(state.briefing_canvasses).map((bc) => {
      if (bc.id === briefing_canvas.id) {
        bc.canvas.variants = bc.canvas.variants.map((v) => {
          if (v.id === layer.image_canvas_variant_id) {
            v.layers.push(layer)
          }
          return v
        })
      }
      return bc
    })
    state.active_scene = state.briefing_canvasses.find((bc) => bc.id === state.active_scene.id)
  },
  // Not the best way to do this, but it works for now
  [UPDATE_BRIEFING_CANVAS_LAYER](state, { layer, briefing_canvas, size }) {
    state.briefing_canvasses = _.cloneDeep(state.briefing_canvasses).map((bc) => {
      if (bc.id === briefing_canvas.id) {
        bc.canvas.variants = bc.canvas.variants.map((v) => {
          if (v.size.name === state.size || v.size.name === size.name) {
            v.layers = v.layers.map((l) => {
              if (l.id === layer.id) {
                return layer
              }
              return l
            })
          }
          const res = updateLinkedLayers(v.layers, layer, bc.canvas.variants)
          const { linked_layers } = res
          const { updated_linked_layers } = res

          if (linked_layers && updated_linked_layers) {
            v.layers = v.layers.map((l) => {
              if (linked_layers.map((l) => l.id).includes(l.id)) {
                return updated_linked_layers.find((ul) => ul.id === l.id)
              }
              return l
            })
          }
          return v
        })
      }
      return bc
    })
    state.active_scene = state.briefing_canvasses.find((bc) => bc.id === state.active_scene.id)
  },
  [UPDATE_BRIEFING_CANVAS](state, briefing_canvas) {
    state.briefing_canvasses = state.briefing_canvasses.map((bc) => {
      if (bc.id === briefing_canvas.id) {
        return briefing_canvas
      }
      return bc
    }).sort((a, b) => (a.ordering < b.ordering ? -1 : 1))
    if (state.active_scene.id === briefing_canvas.id) {
      state.active_scene = { ...briefing_canvas }
    }
  },
  [REMBG](state, rembg) {
    state.rembg = {
      ...state.rembg,
      n_to_be_processed: rembg.n_to_be_processed,
      enabled: rembg.enabled,
      faulty: rembg.faulty,
      state: rembg.state
    }
  },
  [REFRESH_PREVIEW_DATA](state, briefing) {
    state.briefing = briefing
    // state.briefing.preview_feed = briefing.preview_feed
    // state.briefing.n_footage = briefing.n_footage
    // state.briefing.n_items = briefing.n_items
  },
  [INIT_FOLDERS](state, folders) {
    state.folders = folders
  },
  [REFRESH_BRIEFING_CANVASSES](state, briefing) {
    state.briefing_canvasses = briefing.briefing_canvasses
  },
  [ADD_DESIGN](state, briefing_canvas) {
    state.briefing_canvasses.push(briefing_canvas)
    state.briefing_canvasses = [...state.briefing_canvasses, ...briefing_canvas.variants]
  },
  [DELETE_DESIGN](state, briefing_canvas) {
    state.briefing_canvasses = state.briefing_canvasses.filter((bc) => bc.id !== briefing_canvas.id)
    state.active_scene = [...state.briefing_canvasses].pop() || null
  },
  [ADD_ASSET](state, asset) {
    state.briefing.feed.data = [...state.briefing.feed.data, { data: asset }]
    state.active_feed = { ...state.briefing.feed }
  },
  [DELETE_ASSET](state, asset) {
    state.briefing.feed.data = [...state.briefing
      .feed
      .data
      .filter((a) => a.data.adflow_row_id !== asset.adflow_row_id)]
    state.active_feed = { ...state.briefing.feed }
  },
  [PROCESSING](state, { busy, message }) {
    state.processing.busy = busy
    state.processing.message = message
  },
  [UPDATE_ASSET](state, asset) {
    state.briefing.feed.data = state.briefing.feed.data.map((row) => {
      if (row.data.adflow_row_id === asset.data.adflow_row_id) {
        row.data = asset.data
      }
      return row
    })
  },
  [INIT_TEMPLATES](state, { templates }) {
    state.templates.canvasses = templates
  },
  [DELETE_TEMPLATE](state, { template }) {
    state.templates.canvasses = state.templates.canvasses.filter((c) => c.id !== template.id)
  },
  [SET_BATCH](state, batch) {
    console.log('setting batch mutation', batch)
    if (!batch) {
      state.batch = null
    } else {
      state.batch = { ...state.batch, ...batch }
    }
  },
  [ADD_BRIEFING_FOLDER](state, folder) {
    state.folders.unshift(folder)
    state.batch = null
    state.batch_images = []
  },
  [ADD_BATCH_MESSAGE](state, message) {
    state.batch_images.push(message)
  },
  [UPDATE_BRIEFING](state, briefing) {
    state.briefing = { ...state.briefing, ...briefing }
  }
}
