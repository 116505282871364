var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    width: _vm.layer.width + 'px',
    height: _vm.layer.height + 'px',
    position: 'relative',
    overflow: 'hidden'
})},[(_vm.patternUrl !== 'none')?_c('div',{style:({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: ("url('" + _vm.patternUrl + "') repeat center / cover"),
        backgroundSize: _vm.scaledPatternSize,
        opacity: _vm.patternOpacity
    })}):_vm._e(),_vm._v(" "),_c('div',{style:({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: _vm.bgGradient,
        mixBlendMode: 'color-burn'
    })})])}
var staticRenderFns = []
export { render, staticRenderFns }