<template>
    <div class="layer-config__essentials">
        <div v-for="option in options.options" :key="option.name">
            <div v-if="isList(option)" style="display: flex; gap: 8px">
                <GeneratedOptions :layer="layer" :fonts="fonts" :mediaFolder="mediaFolder" :onUpdate="onUpdate"
                    :palette="palette" :option="opt" v-for="opt in option" />
            </div>
            <div v-else>
                <GeneratedOptions :layer="layer" :fonts="fonts" :mediaFolder="mediaFolder" :onUpdate="onUpdate"
                    :palette="palette" :option="option" />
            </div>
        </div>
    </div>
</template>

<script>
const predefinedComponents = {}
const requireComponent = require.context(
    '../../components/predefined',
    false,
    /[\w-]+\.js$/
)

const requireVideoComponent = require.context(
    '../../components/predefined/video',
    false,
    /[\w-]+\.js$/
)

const importComponents = (requireContext, prefix = '') => {
    requireContext.keys().forEach(fileName => {
        const componentConfig = requireContext(fileName)
        const componentName = prefix + fileName
            .replace(/^\.\/(.*)\.\w+$/, '$1')
            .replace(/-([a-z])/g, (g) => g[1].toUpperCase())
        predefinedComponents[componentName] = componentConfig.default || componentConfig
    })
}

importComponents(requireComponent)
importComponents(requireVideoComponent, 'video/')

import GeneratedOptions from './generated_options.vue'

export default {
    components: {
        GeneratedOptions
    },
    props: {
        layer: {
            type: Object,
            required: true,
            default: () => ({})
        },
        fonts: {
            type: Array,
            required: true,
            default: () => ([])
        },
        mediaFolder: {
            type: String,
            required: true,
            default: () => ''
        },
        onUpdate: {
            type: Function,
            required: true,
            default: () => () => { }
        },
        palette: {
            type: Array,
            required: true,
            default: () => []
        },
    },
    data() {
        return {
            options: { options: [] },
        }
    },
    mounted() {
        const componentType = this.getComponent(this.layer)
        const component = new componentType()
        this.options = component.configFields()
    },
    watch: {
        layer: {
            deep: true,
            handler(newval) {
                const componentType = this.getComponent(newval)
                const component = new componentType()
                this.options = component.configFields()
            }
        }
    },
    methods: {
        isList(obj) {
            return Array.isArray(obj)
        },
        getComponent(layer) {
            const componentType = layer.config.component.type
            return predefinedComponents[componentType] || predefinedComponents['default']
        }
    }

}
</script>