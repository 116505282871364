<template>
    <div :style="{
        width: layer.width + 'px',
        height: layer.height + 'px',
        position: 'relative',
        overflow: 'hidden'
    }">
        <!-- Pattern Background -->
        <div v-if="patternUrl !== 'none'" :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `url('${patternUrl}') repeat center / cover`,
            backgroundSize: scaledPatternSize,
            opacity: patternOpacity
        }">
        </div>

        <!-- Gradient Overlay -->
        <div :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: bgGradient,
            mixBlendMode: 'color-burn'
        }">
        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers';
import FontMixin from '../mixins/font';

export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],
    computed: {
        patternDetails() {
            return this.layer?.config?.bg_gradient?.pattern || { url: 'none', size: 10 };
        },
        patternUrl() {
            return this.patternDetails.url;
        },
        scaledPatternSize() {
            const baseSize = this.patternDetails.size; // Base size from the pattern property
            const scaleFactor = (this.layer?.config?.bg_gradient?.pattern_size || 10) / 10; // Convert scale to factor (1-100 -> 0.1-10)
            return `${baseSize * scaleFactor}px`;
        },
        patternOpacity() {
            const visibility = this.layer?.config?.bg_gradient?.pattern_visibility || 100; // Default to 100%
            return visibility / 100; // Convert to a range from 0 to 1
        },
        gradientPosition() {
            const direction = this.layer?.config?.bg_gradient?.direction || 'center';
            switch (direction) {
                case 'top': return 'center bottom';
                case 'left': return 'right center';
                case 'bottom': return 'center top';
                case 'right': return 'left center';
                default: return 'center';
            }
        },
        gradientDirection() {
            const direction = this.layer?.config?.bg_gradient?.direction || 'right';
            switch (direction) {
                case 'top': return '0deg';
                case 'left': return '270deg';
                case 'bottom': return '180deg';
                case 'right':
                default: return '90deg';
            }
        },
        bgGradient() {
            const color1 = this.layer?.config?.bg_gradient?.color_1 || '#ffffff';
            const color2 = this.layer?.config?.bg_gradient?.color_2 || '#000000';
            const gradientType = this.layer?.config?.bg_gradient?.type || 'linear';

            if (gradientType === 'radial') {
                return `radial-gradient(circle at ${this.gradientPosition}, ${color1} 0%, ${color2} 100%)`;
            } else {
                return `linear-gradient(${this.gradientDirection}, ${color1} 0%, ${color2} 100%)`;
            }
        },
    },
    watch: {
        // Watch pattern and pattern_size for changes
        'layer.config.bg_gradient.pattern': {
            handler() {
                // Ensure scaledPatternSize updates correctly
                this.$forceUpdate();
            },
            immediate: true,
        },
        'layer.config.bg_gradient.pattern_size': {
            handler() {
                // Ensure scaledPatternSize updates correctly
                this.$forceUpdate();
            },
            immediate: true,
        },
    },
};
</script>