import Background from './types/background'
import Layer from '../../store/modules/layers/model'

export default class BgGradient extends Background {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Gradient'
  }

  labels() {
    return ['background']
  }

  icon() {
    return 'fa-solid fa-heart'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        [
          {
            type: 'color',
            name: 'Color 1',
            key: 'color_1',
          },
          {
            type: 'color',
            name: 'Color 2',
            key: 'color_2',
          },
        ],
        {
          type: 'buttonGroup',
          name: 'Direction',
          hide_label: false,
          key: 'direction',
          options: [
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-left',
              value: 'left',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-right',
              value: 'right',
            },
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-up',
              value: 'top',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-down',
              value: 'bottom',
            }
          ]
        },
        {
          /**
           * Patterns sourced from: https://www.transparenttextures.com/
           * Examples:
           * - black-scales: https://www.transparenttextures.com/patterns/black-scales.png
           * - bo-play: https://www.transparenttextures.com/patterns/bo-play.png
           * - basketball: https://www.transparenttextures.com/patterns/basketball.png
           */
          type: 'buttonGroup',
          name: 'Pattern',
          hide_label: false,
          key: 'pattern',
          options: [
            {
              type: 'text',
              name: 'none',
              value: {
                id: 'none',
                size: 10,
                url: null
              }
            },
            {
              type: 'text',
              name: 'Black scales',
              value: {
                id: 'black-scales',
                size: 40,
                url: 'https://cdn.adflow.io/public/c2216f38-20ee-4ea6-82c4-8372b28ea285.png',
              }
            },
            {
              type: 'text',
              name: 'Bo Play',
              value: {
                id: 'bo-play',
                size: 40,
                url: 'https://cdn.adflow.io/public/f419c33c-1a3a-48e4-82ee-f7a3cc3dda12.png',
              }
            },
            {
              type: 'text',
              name: 'Basketball',
              value: {
                id: 'basketball',
                size: 200,
                url: 'https://cdn.adflow.io/public/4fb61f30-7a1f-4970-b319-67ae561b2388.png',
              }
            },
          ]
        },
        {
          type: 'slider',
          name: 'Pattern size',
          key: 'pattern_size',
          min: 1,
          max: 100,
          step: 1,
        },
        {
          type: 'slider',
          name: 'Pattern visibility',
          key: 'pattern_visibility',
          min: 1,
          max: 100,
          step: 1,
        },
        {
          type: 'buttonGroup',
          name: 'Type',
          hide_label: false,
          key: 'type',
          options: [
            {
              type: 'text',
              name: 'lineair',
              value: 'lineair',
            },
            {
              type: 'text',
              name: 'radial',
              value: 'radial',
            }
          ]
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            bg_gradient: {
              ...baseConfig.config.bg_gradient,
              color_1: '#BEA681',
              color_2: '#FFF3E0',
              direction: 'top',
              type: 'radial'
            }
          },
        }]
      },
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            bg_gradient: {
              ...baseConfig.config.bg_gradient,
              color_1: '#BE8181',
              color_2: '#1D1212',
              direction: 'bottom',
              type: 'radial',
              pattern: {
                id: 'black-scales',
                url: 'https://cdn.adflow.io/public/c2216f38-20ee-4ea6-82c4-8372b28ea285.png',
                size: 40,
              },
              pattern_size: 15,
              pattern_visibility: 22,
            }
          },
        }]
      },
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            bg_gradient: {
              ...baseConfig.config.bg_gradient,
              color_1: '#CECECE',
              color_2: '#494949',
              direction: 'bottom',
              type: 'radial',
              pattern: {
                id: 'bo-play',
                url: 'https://cdn.adflow.io/public/f419c33c-1a3a-48e4-82ee-f7a3cc3dda12.png',
                size: 40,
              },
              pattern_size: 14,
              pattern_visibility: 41,
            }
          },
        }]
      },
    ]
  }

  baseConfig({ canvas = { width: 600, height: 600 } } = {}) {
    return {
      layer_type: 'component',
      width: canvas.width,
      height: canvas.height,
      ...this.opts,
      config: {
        component: {
          type: 'bg_gradient',
          name: 'Gradient',
        },
        bg_gradient: {
          color_1: '#212A38',
          color_2: '#2B4774',
          direction: 'top',
          type: 'lineair',
          pattern: {
            id: 'none',
            type: 'none',
            size: 40,
          },
          pattern_size: 20,
          pattern_visibility: 50,
        }
      }
    }
  }

  config({ canvas = { width: 600, height: 600 } } = {}) {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig({ canvas }))
      ]
    }
  }
}
