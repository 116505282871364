import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const store = {
  state: {
    editor_ref: null,
    version: 'v2',
    tab: 'data',
    credits: 0,
    event_target: new EventTarget(),
    animation_controller_map: {},
    active_feed: null,
    active_scene: null,
    scene_context: null,
    player_progress: 0,
    player_duration: 0,
    player_paused: true,
    global_zoom: 1,
    show_size_selector: false,
    initializing: true,
    active_layer: null,
    show_safe_margins: false,
    template_picker: {
      show: false,
      canvas: null,
      preview_feeds: [],
      variants: []
    },
    size: 'facebook_catalog',
    size_hover: null,
    ui_state: 'DEFAULT',
    ui_edit_state: 'DEFAULT',
    available_sizes: [],
    brandkit: {},
    batch_images: [],
    batch: null,
    templates: {
      canvasses: [],
      page: 0
    },
    rembg: {
      enabled: false,
      id: null,
      faulty: [],
      n_to_be_processed: 0,
      state: 0
    },
    processing: {
      busy: false,
      message: ''
    },
    briefing_canvasses: [],
    folders: [],
    sounds: [
      {
        name: 'None',
        url: null
      },
      {
        name: 'Bounce',
        url: 'https://cdn.adflow.io/public/bounce.mp3'
      },
      {
        name: 'Electronic rock king',
        url: 'https://cdn.adflow.io/public/electronic-rock-king-around-here.mp3'
      },
      {
        name: 'FLoating abstract',
        url: 'https://cdn.adflow.io/public/floating-abstract.mp3'
      },
      {
        name: 'Fluidity',
        url: 'https://cdn.adflow.io/public/fluidity.mp3'
      },
      {
        name: 'Fun life',
        url: 'https://cdn.adflow.io/public/fun-life.mp3'
      },
      {
        name: 'Hip hop is',
        url: 'https://cdn.adflow.io/public/hip-hop-is.mp3'
      },
      {
        name: 'Hip hop just in my style',
        url: 'https://cdn.adflow.io/public/hip-hop-just-in-my-style.mp3'
      },
      {
        name: 'Jazzy abstract beat',
        url: 'https://cdn.adflow.io/public/jazzy-abstract-beat.mp3'
      },
      {
        name: 'Sexy fashion beats simulate',
        url: 'https://cdn.adflow.io/public/sexy-fashion-beats-simulate.mp3'
      },
      {
        name: 'The podcast intro',
        url: 'https://cdn.adflow.io/public/the-podcast-intro.mp3'
      }
    ],
    briefing: {
      id: null,
      title: null,
      name: null,
      subtitle: null,
      cta: null,
      feed: {
        data: []
      },
    }
  },
  mutations,
  actions,
  getters
}

export default store
