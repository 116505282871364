import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class Zoom extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })
  }

  name() {
    return 'Zoom'
  }

  cleanup() {

  }

  prepare() {
    $(`.layer_${this.uid}_${this.layer.id}`).css('transform', `scale(${this.conf().direction ? 1.1 : 1.15})`)
    if (this.has_focus_points(this.layer.config.image_url)) {
      $(`.layer_${this.uid}_${this.layer.id}`).css(
        'transform-origin',
        this.calc_bg_pos(this.focus_points(this.layer.config.image_url))
      )
    } else {
      $(`.layer_${this.uid}_${this.layer.id}`).css('transform-origin', 'center')
    }
  }

  static int_name() {
    return 'zoom'
  }

  static exposed_to() {
    return ['component', 'image', 'logo', 'text', 'slider',
      'text_area', 'video', 'price', 'background']
  }

  static properties() {
    return {
      ...super.properties(),
      direction: {
        type: 'Boolean',
        default: true,
        display: {
          true: 'in',
          false: 'out'
        }
      }
    }
  }

  animation() {
    return anime.timeline({
      duration: this.duration,
      autoplay: false,
    }).add(this.animation_config())
  }

  animation_config(duration = this.duration) {
    if (this.loop()) {
      duration = this.duration / 2
    }

    let scale = [1.1, 1.15]
    if (!this.conf().direction) {
      scale = scale.reverse()
    }
    return {
      begin: () => {
        if (this.has_focus_points(this.layer.config.image_url)) {
          $(`.layer_${this.uid}_${this.layer.id}`).css(
            'transform-origin',
            this.calc_bg_pos(this.focus_points(this.layer.config.image_url))
          )
        } else {
          $(`.layer_${this.uid}_${this.layer.id}`).css('transform-origin', 'center')
        }
      },
      targets: `.layer_${this.uid}_${this.layer.id}`,
      scale,
      duration,
      loop: this.loop(),
      direction: this.loop() ? 'alternate' : 'normal',
      easing: this.easing()
    }
  }

  has_focus_points(source) {
    try {
      const link = new URL(source)
      const focus_x = link.searchParams.get('focus_x')
      const focus_y = link.searchParams.get('focus_y')
      return !!(focus_x && focus_y)
    } catch (e) {
      return false
    }
  }

  focus_points(source) {
    try {
      const link = new URL(source)
      const focus_x = link.searchParams.get('focus_x')
      const focus_y = link.searchParams.get('focus_y')
      return { focusx: focus_x, focusy: focus_y }
    } catch (e) {
      return { focusx: 0, focusy: 0 }
    }
  }

  calc_bg_pos({ focusx, focusy }) {
    let y = (Math.abs(focusy) / 2) * 100
    let x = (Math.abs(focusx) / 2) * 100
    if (this.focus_points.y < 0) {
      y += 50
    } else {
      y = 50 - (Math.abs(focusy) * 50)
    }
    if (this.focus_points.x > 0) {
      x += 50
    } else {
      x = 50 - (Math.abs(focusx) * 50)
    }
    return `${x}% ${y}%`
  }
}
