import Layer from '../../../store/modules/layers/model'
import VideoComponent from '../../video_component'

export default class Slider extends VideoComponent {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Slider'
  }

  labels() {
    return ['video slider']
  }

  icon() {
    return 'fa-solid fa-sliders-h'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'slider',
          name: 'Number of images',
          key: 'number_of_images',
          min: 2,
          max: 8,
          step: 1,
        },
        {
          type: 'buttonGroup',
          name: 'image size',
          key: 'config.object_fit',
          options: [
            {
              type: 'icon',
              name: 'Fit',
              icon: 'fa-regular fa-compress',
              value: 'contain',
            },
            {
              type: 'icon',
              name: 'Cover',
              icon: 'fa-regular fa-expand',
              value: 'cover',
            },
          ]
        },
        {
          type: 'multiAssetPicker',
          name: 'Images',
          key: 'images',
          number_of_assets_key: 'number_of_images',
        }
      ]
    }
  }

  variants() {
    // const baseConfig = this.baseConfig()

    return [

    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      width: 350,
      height: 250,
      fontSize: 106,
      ...this.opts,
      config: {
        v_align: 'center',
        line_height: 1,
        component: {
          type: 'video/slider',
          name: 'Slider',
        },
        animation: {
          delay_percentage: 0,
          duration_percentage: 100,
          in: null,
          while: null,
          out: null,
        },
        animations: [
          {
            type: 'inwhileout'
          },
          {
            type: 'slider',
            in_place: false,
            easing: 'easeInOutExpo'
          }],
        object_fit: 'contain',
        show_animations: true,
        'video/slider': {
          number_of_images: 2,
          images: {
            0: 'https://cdn.adflow.io/public/d7c0cf54-fdef-4e93-bd96-89d783427128.png',
            1: 'https://cdn.adflow.io/public/d7c0cf54-fdef-4e93-bd96-89d783427128.png',
          },
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
