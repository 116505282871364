<template>
  <div :style="{ width: layer.width + 'px' }">
    <svg
      :width="layer.width"
      :height="totalSvgHeight"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- Buitenste stroke (per regel) -->
      <text
        v-for="(line, index) in wrappedText"
        :key="`stroke-${index}`"
        :x="getXPosition(layer.align)"
        :y="verticalCenter + index * scaledFontSize * lineHeight"
        :text-anchor="getTextAnchor(layer.align)"
        :font-family="font"
        :font-size="scaledFontSize"
        :stroke="bgColor"
        :stroke-width="scaledStrokeWidth"
        stroke-linejoin="round"
        fill="none"
      >
        {{ line }}
      </text>

      <!-- Binnenste tekst (per regel) -->
      <text
        v-for="(line, index) in wrappedText"
        :key="`text-${index}`"
        :x="getXPosition(layer.align)"
        :y="verticalCenter + index * scaledFontSize * lineHeight"
        :text-anchor="getTextAnchor(layer.align)"
        :font-family="font"
        :font-size="scaledFontSize"
        :fill="layer.color"
      >
        {{ line }}
      </text>
    </svg>
  </div>
</template>

<script>
import { highlight } from '../../../lib/parsers';
import FontMixin from '../mixins/font';

export default {
  props: ['layer', 'animation_uid'],
  mixins: [FontMixin],
  computed: {
    component_name() {
      return this.layer.config.component.type;
    },
    line_width() {
      const rawValue = this.layer.config[this.component_name]?.border_width || 10; // Default to 10
      return rawValue / 10; // Convert 0-100 input to 0.1-10 factor
    },
    scaledFontSize() {
      const scaleFactor = 0.45; // Apply the scale factor
      return this.layer.fontSize * scaleFactor;
    },
    scaledStrokeWidth() {
      return this.scaledFontSize * 0.1 * this.line_width; // Stroke scales with scaledFontSize
    },
    bgColor() {
      return this.layer.config[this.component_name]?.bg_color || 'white';
    },
    lineHeight() {
      return this.layer.config.line_height || 1.5; // Default line-height is 1.5
    },
    totalSvgHeight() {
      const textHeight = this.scaledFontSize * this.lineHeight * this.wrappedText.length;
      const padding = this.scaledFontSize * 0.4; // 20% extra padding
      return textHeight + 2 * padding; // Extra ruimte boven en onder
    },
    verticalCenter() {
      const textHeight = this.scaledFontSize * this.lineHeight * this.wrappedText.length;
      const padding = this.scaledFontSize * 0.4; // Zelfde padding als hierboven
      return padding + this.scaledFontSize; // Startpositie voor centreren
    },
    wrappedText() {
      const maxWidth = this.layer.width * 0.8; // Maximum width of the text in pixels
      const paragraphs = this.layer.text.split('\n'); // Split input text by line breaks
      const lines = [];

      const ctx = document.createElement('canvas').getContext('2d');
      ctx.font = `${this.scaledFontSize}px ${this.font}`;

      paragraphs.forEach((paragraph) => {
        const words = paragraph.split(' ');
        let currentLine = '';

        words.forEach((word) => {
          const testLine = currentLine + (currentLine ? ' ' : '') + word;
          const testWidth = ctx.measureText(testLine).width;

          if (testWidth > maxWidth) {
            lines.push(currentLine); // Push the current line
            currentLine = word; // Start a new line with the current word
          } else {
            currentLine = testLine; // Add the word to the current line
          }
        });

        if (currentLine) lines.push(currentLine); // Add the last line of the paragraph
      });

      return lines;
    },
  },
  methods: {
    getTextAnchor(align) {
      if (align === 'left') return 'start';
      if (align === 'right') return 'end';
      return 'middle'; // Default is center
    },
    getXPosition(align) {
      const margin = this.scaledStrokeWidth; // Extra marge gebaseerd op de stroke-dikte
      if (align === 'left') return margin; // Begin iets na de linker rand
      if (align === 'right') return this.layer.width - margin; // Stop iets voor de rechter rand
      return this.layer.width / 2; // Center blijft ongewijzigd
    },
  },
};
</script>