import axios from 'axios';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

const api = {
  async update_campaign_briefing(campaign_briefing) {
    const result = await axios.patch(`/campaign_briefings/${campaign_briefing.id}.json`, {
      campaign_briefing
    })
    return result.data
  },
  async toggle_rembg(state, enabled) {
    const result = await axios.patch(
      `${window.location.origin}/data/feeds/${state.briefing.feed.complex_identifier}/feed_addons/${state.briefing.rembg.id}.json`,
      {
        data_addons_image_background_removal: {
          enabled
        }
      }
    )
    return result.data
  },
  async create_template_from_scene(briefing_canvas, name) {
    const result = await axios.post(`/briefings/${briefing_canvas.briefing_id}/briefing_canvasses/${briefing_canvas.id}/template.json`, {
      name
    })
    return result.data
  },
  async rembg_process(state) {
    const result = await axios.post(`${window.location.origin}/data/feeds/${state.briefing.feed.complex_identifier}/feed_addons/${state.briefing.rembg.id}/process_feed.json`)
    return result.data
  },
  async upload_file(file, config = {}) {
    const form_data = new FormData()
    form_data.append('file', file)
    form_data.append('feed_element', JSON.stringify({}))
    const file_url = await axios.post(`${window.location.origin}/user_media_folder/assets.json`, form_data, config)
    return file_url.data
  },
  async briefing_folders(briefing) {
    const result = await axios.get(`${window.location.origin}/briefings/${briefing.id}/folders.json`)
    return result.data
  },
  async briefing_canvas_preview(canvas, briefing) {
    let params = ''
    if (canvas.adflow_template) {
      params = `template_id=${canvas.id}`
    } else {
      params = `canvas_id=${canvas.id}`
    }
    const result = await axios.get(`${window.location.origin}/briefing_canvasses/preview.json?${params}&briefing_id=${briefing.id}`)
    return result.data
  },
  async template_scenes(briefing, { from_cache = false }) {
    let cache = ''
    if (from_cache) {
      cache = `?from_cache=${from_cache}`
    }
    const result = await axios.get(`${window.location.origin}/templates/${briefing.id}/scenes.json${cache}`)
    return result.data
  },
  async briefing_canvasses(briefing, { from_cache = false }) {
    let cache = ''
    if (from_cache) {
      cache = `?from_cache=${from_cache}`
    }
    const result = await axios.get(`${window.location.origin}/briefings/${briefing.id}/briefing_canvasses.json${cache}`)
    return result.data
  },
  async get_briefing(briefing) {
    const result = await axios.get(`${window.location.origin}/briefings/${briefing.id}.json`)
    return result.data
  },
  async remove_background(asset) {
    const result = await axios.post(`${window.location.origin}/user_media_folder/assets/${asset.id}/remove_background.json`)
    return result.data
  },
  async get_media_folder() {
    const result = await axios.get(`${window.location.origin}/user_media_folder.json`)
    return result.data
  },
  async get_folder(folder) {
    const result = await axios.get(`${window.location.origin}/folders/${folder.complex_identifier}.json`)
    return result.data
  },
  async build_briefing(briefing) {
    const result = await axios.post(`/briefings/${briefing.id}/build.json`, {})
    return result.data
  },
  async build_briefing_video(briefing, sizes = []) {
    const result = await axios.post(`/briefings/${briefing.id}/build_video.json?sizes=${sizes}`, briefing)
    return result.data
  },
  async build_briefing_image(briefing, sizes = []) {
    const result = await axios.post(`/briefings/${briefing.id}/build.json?sizes=${sizes}`, briefing)
    return result.data
  },
  async get_canvasses(briefing) {
    const result = await axios.get(`/briefings/${briefing.id}/canvasses.json`)
    return result.data
  },
  async get_credits(briefing) {
    const result = await axios.get(`/brands/${briefing.brand_id}/credits.json`)
    return result.data
  },
  async update_briefing(briefing) {
    const result = await axios.patch(`/briefings/${briefing.id}.json`, {
      briefing
    })
    return result
  },
  async delete_canvassable(canvassable) {
    const result = await axios.delete(`/designs/${canvassable.id}.json`)
    return result
  },
  async dup_canvassable(canvassable) {
    const result = await axios.get(`/designs/${canvassable.id}/dup.json`)
    return result.data
  },
  async dup_briefing_canvas(briefing_canvas) {
    const result = await axios.get(`/briefings/${briefing_canvas.briefing_id}/briefing_canvasses/${briefing_canvas.id}/dup.json`)
    return result.data
  },
  async delete_briefing_canvas(briefing_canvas) {
    const result = await axios.delete(`/briefings/${briefing_canvas.briefing_id}/briefing_canvasses/${briefing_canvas.id}.json`)
    return result
  },
  async update_briefing_canvas(briefing_canvas) {
    const result = await axios.patch(`/briefings/${briefing_canvas.briefing_id}/briefing_canvasses/${briefing_canvas.id}.json`, {
      briefing_canvas
    })
    return result
  },
  async create_briefing_canvas(briefing, canvas) {
    const result = await axios.post(`/briefings/${briefing.id}/briefing_canvasses.json`, {
      briefing_canvas: {
        canvas_id: canvas.canvas_id,
        briefing_id: briefing.id
      }
    })
    return result.data
  },
  async get_briefing_canvas(briefing_canvas) {
    const result = await axios.get(`/briefings/${briefing_canvas.briefing_id}/briefing_canvasses/${briefing_canvas.id}.json`)
    return result.data
  },
  async create_briefing_canvas_tree(briefing, canvas, variants) {
    const prms = {
      briefing_canvas: {
        canvas_id: canvas.canvas_id,
        briefing_id: briefing.id,
      },
      variants
    }

    if (canvas.adflow_template) {
      prms.template_name = canvas.name
    }

    const result = await axios.post(`/briefings/${briefing.id}/briefing_canvasses/create_tree.json`, prms)
    return result.data
  },
  async delete_feed_row(complex_id, row_id) {
    const result = await axios.delete(`/data/feeds/${complex_id}/rows/${row_id}.json`)
    return result
  },
  async update_feed_rows(complex_id, rows) {
    const result = await axios.patch(`/data/feeds/${complex_id}/update_all_rows.json`, {
      rows
    })
    return result
  },
  async update_feed_row(complex_id, row) {
    const result = await axios.patch(`/data/feeds/${complex_id}/rows/${row.data.adflow_row_id}.json`, {
      data_row: {
        data: row.data
      }
    })
    return result
  },
  async create_feed_row(complex_id, row) {
    const result = await axios.post(`/data/feeds/${complex_id}/rows.json`, {
      data_row: row,
    })
    return result.data
  },
  async filter_feed({
    feed_id,
    conditions,
    gate,
    page
  }) {
    const result = await axios.post(`/data/feeds/${feed_id}/filter.json`, {
      conditions: JSON.stringify(conditions),
      gate,
      page
    })
    return result
  },
}

export default api
