<template>
  <div>
    <modal :name="`confirm_dialog-${id}`" height="auto" @closed="() => close()">
      <div class="modal__flex">
        <div class="modal__header">
          <h4>{{ title }}</h4>
        </div>
        <div class="modal__flex_body">
          {{ body }}
        </div>
        <div class="modal__footer">
          <div class="modal__footer__buttons_wrapper">
            <button class="button--grey" v-on:click="() => close()">Cancel</button>
            <button v-on:click="() => confirm()">Confirm</button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>


<script>

export default {
  data() {
    return {

    }
  },

  props: {
    id: {
      type: Number,
      default: () => {
        return Math.floor(Math.random() * 1000);
      }
    },
    confirm: {
      type: Function,
      default: () => { }
    },
    close: {
      type: Function,
      default: () => { }
    },
    show: {
      type: Boolean,
      default: false
    },
    body: {
      type: String,
    },
    title: {
      type: String,
    }
  },

  watch: {
    show(newVal, oldVal) {
      if (newVal) {
        this.$modal.show('confirm_dialog-' + this.id)
      } else {
        this.$modal.hide('confirm_dialog-' + this.id)
      }
    }
  },

  methods: {

  }
}

</script>
