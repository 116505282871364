import { debounce } from 'debounce';
import api from './api'
import {
  INIT, SET_BATCH, ADD_BATCH_MESSAGE, ADD_BRIEFING_FOLDER, REMBG,
  SET_TEMPLATE_TEMPLATE_PICKER,
  INIT_TEMPLATES, DELETE_DESIGN, DELETE_ASSET, UPDATE_ASSET,
  UPDATE_BRIEFING, INIT_FOLDERS,
  ADD_ASSET, ADD_DESIGN, PROCESSING, REFRESH_PREVIEW_DATA,
  REFRESH_BRIEFING_CANVASSES, ACTIVATE_SCENE,
  SET_PREVIEW_TEMPLATE_PICKER, CREATE_SCENE,
  DELETE_FEED_ROW, ADD_FEED_ROW, UPDATE_BRIEFING_CANVAS_LAYER,
  UPDATE_BRIEFING_CANVAS, UPDATE_SCENE_FEED, SET_SHOW_TEMPLATE_PICKER, GET_BRIEFING_CANVAS,
  SCENE_REORDER, DELETE_TEMPLATE, SET_UI_STATE, SET_CREDITS, FINISH_INITIAL_LOAD, SET_SCENE_CONTEXT,
  SET_TAB
} from './mutation_types'
import consumer from '../../channels/consumer'

const refresh_canvasses_and_preview = debounce((dispatch) => {
  dispatch('load_briefing_canvasses')
  dispatch('load_preview_data')
  dispatch('load_templates')
  dispatch('rembg')
}, 1000)

const update_briefing_remote = debounce((dispatch, briefing) => {
  dispatch('update_briefing_remote', briefing)
  setTimeout(() => {
    dispatch('load_preview_data')
  }, 200)
}, 500)

const listen_to_batch = (dispatch, commit, state, type = 'video', batch) => { // eslint-disable-line
  consumer.subscriptions.create({ channel: 'BatchChannel', id: batch.id }, {
    received(data) {
      if (state.version === 1) {
        console.log('executing setting batch from dispatcher', data, data.batch_status)
        commit(SET_BATCH, data.batch_status)
        if (data.event.message.includes('https://')) {
          commit(ADD_BATCH_MESSAGE, data.event.message)
        }
        if (data.batch_status && data.batch_status.finished === true) {
          api.get_folder(data.folder).then((folder) => {
            commit(ADD_BRIEFING_FOLDER, folder)
          })
        }
      } else if (data.event.message.includes('https://')) {
        commit(SET_BATCH, data.batch_status)
        dispatch('load_folders')
        if (type === 'video') {
          dispatch('get_credits')
        }
        if (data.batch_status && data.batch_status.finished) {
          commit(SET_BATCH, null)
        }
      }
    }
  })
}

export default {
  init({ commit, dispatch, getters }, {
    briefing, brandkit, fonts, options, version = 2
  }) {
    commit(INIT, {
      briefing, brandkit, version, options, fonts
    })
    commit(SET_UI_STATE, 'INIT')
    dispatch('load_briefing_canvasses').then(() => {
      commit(SET_UI_STATE, 'DEFAULT')
      commit(FINISH_INITIAL_LOAD)
      commit(SET_SCENE_CONTEXT, getters.root_scenes[0])
    })
    if (version === 1) dispatch('load_folders')
    dispatch('load_templates')
    dispatch('load_folders')
    consumer.subscriptions.create({ channel: 'FeedChannel', id: briefing.feed.complex_identifier }, {
      received(data) {
        if (briefing.rembg.name === data.addon) {
          if (data.total > 0 && data.indice > 0) {
            const percentage = Math.round((data.indice / data.total) * 100)
            if (percentage >= 100) {
              dispatch('load_preview_data')
              commit(PROCESSING, {
                busy: true,
                message: 'Finished'
              })
              setTimeout(() => {
                commit(PROCESSING, {
                  busy: false,
                  message: ''
                })
              }, 500)
            } else {
              commit(PROCESSING, {
                busy: true,
                message: `Processing data ${percentage}%`
              })
            }
          }
        }
      }
    })
  },
  async DUPLICATE_CANVAS({ state, commit }, { canvassable }) {
    const result = await api.dup_canvassable(canvassable)
    commit(INIT_TEMPLATES, { templates: [result, ...state.templates.canvasses] })
  },
  async DELETE_CANVAS({ commit }, { canvassable }) {
    await api.delete_canvassable(canvassable)
    commit(DELETE_TEMPLATE, { template: canvassable })
  },
  async get_credits({ commit, state }) {
    const credits = await api.get_credits(state.briefing)
    commit(SET_CREDITS, credits.credits)
  },
  async create_template_from_scene({ state }, { scene, callback, name }) { // eslint-disable-line
    const result = await api.create_template_from_scene(scene, name)
    if (callback) callback(result)
    return result
  },
  async update_feed({ commit }, { scene, rows }) {
    commit(UPDATE_SCENE_FEED, { scene, feed: rows })
    // update_feed(feed_id, rows)
  },
  async [UPDATE_SCENE_FEED]({ commit }, { scene, feed }) {
    commit(UPDATE_SCENE_FEED, { scene, feed })
  },
  async [SCENE_REORDER]({ commit, state }, { old_index, new_index }) {
    const bcs = [...state.briefing_canvasses]
    const item = bcs.splice(old_index, 1)[0]
    bcs.splice(new_index, 0, item)
    await Promise.all(bcs.map(async (bc, index) => {
      bc.ordering = (index + 1) * 10
      commit(UPDATE_BRIEFING_CANVAS, bc)
      await api.update_briefing_canvas(bc)
    }))
  },
  async [CREATE_SCENE]({ dispatch, commit, state }, { canvas } = { canvas: null }) {
    let c = { ...state.template_picker.canvas }
    let variants = [...state.template_picker.variants]
    if (canvas) {
      c = { ...canvas }
      variants = []
    }
    // commit(SET_SHOW_TEMPLATE_PICKER, false)
    commit(PROCESSING, {
      busy: true,
      message: 'Creating scene'
    })
    commit('SET_VARIANTS_TEMPLATE_PICKER', [])
    commit('SET_TEMPLATE_TEMPLATE_PICKER', null)
    const result = await api.create_briefing_canvas_tree(
      state.briefing,
      c,
      variants
    )

    commit(ADD_DESIGN, result)
    dispatch(ACTIVATE_SCENE, result)
    commit(PROCESSING, {
      busy: false,
      message: ''
    })
  },
  [UPDATE_BRIEFING_CANVAS_LAYER]({ commit }, { layer, briefing_canvas, size }) {
    const variant = briefing_canvas.canvas.variants.find((v) => v.size.name === size.name);
    const oldLayer = variant.layers.find((l) => l.id === layer.id);
    if (oldLayer.config.show_animations === false && layer.config.show_animations === true) {
      commit('SET_UI_EDIT_STATE', 'ANIMATING')
    }
    commit(UPDATE_BRIEFING_CANVAS_LAYER, { layer, briefing_canvas, size });
  },
  async [SET_TEMPLATE_TEMPLATE_PICKER]({ commit, state }, canvas) {
    commit(SET_TEMPLATE_TEMPLATE_PICKER, canvas)
    if (canvas === null) {
      commit(SET_PREVIEW_TEMPLATE_PICKER, [])
    } else {
      const result = await api.briefing_canvas_preview(canvas, state.briefing)
      commit(SET_PREVIEW_TEMPLATE_PICKER, [...result.feeds])
    }
  },
  async [GET_BRIEFING_CANVAS]({ commit }, { briefing_canvas }) {
    const result = await api.get_briefing_canvas(briefing_canvas)
    commit(GET_BRIEFING_CANVAS, result)
  },
  async rembg({ commit, state }) {
    const result = await api.rembg_process(state)
    commit(REMBG, result)
  },
  async toggle_rembg({ commit, state }, checked) {
    commit(PROCESSING, {
      busy: true,
      message: `${checked ? 'Enabling' : 'Disabling'} background removal`
    })
    const result = await api.toggle_rembg(state, checked)
    commit(REMBG, result)

    if (!checked) {
      setTimeout(() => {
        commit(PROCESSING, {
          busy: false
        })
      }, 1000)
    }
  },
  async reorder_assets({ dispatch }, { list }) {
    list.forEach((asset, index) => {
      asset = { ...asset, data: { ...asset.data, adflow_order: index } }
      dispatch('update_asset', { row: asset, remote: true })
    })
  },
  async reclassify_asset({ dispatch }, { group, row }) {
    row = { ...row }
    let classification = 'unclassified'
    if (group === 'products') {
      classification = 'product'
    } else if (group === 'backgrounds') {
      classification = 'lifestyle'
    }
    row.data.adflow_image_classification = classification
    dispatch('update_asset', { row, remote: true })
  },
  [SET_SHOW_TEMPLATE_PICKER]({ dispatch, commit }, state) {
    commit(SET_SHOW_TEMPLATE_PICKER, state)

    if (state) {
      commit(PROCESSING, { busy: true, message: 'Loading templates' })
      refresh_canvasses_and_preview(dispatch)
    }
  },
  async get_briefing({ commit, state }) {
    commit(PROCESSING, { busy: true })
    const briefing = await api.get_briefing(state.briefing)
    commit(INIT, briefing)
    commit(PROCESSING, { busy: false })
  },
  async load_folders({ commit, state }) {
    commit(PROCESSING, { busy: true, message: 'Retrieving previous renders' })
    const result = await api.briefing_folders(state.briefing)
    commit(INIT_FOLDERS, result)
    commit(PROCESSING, { busy: false })
  },
  async load_preview_data({ commit, state }) {
    commit(PROCESSING, { busy: true, message: 'Refreshing previews' })
    const briefing = await api.get_briefing(state.briefing)
    commit(REFRESH_PREVIEW_DATA, briefing)
    commit(PROCESSING, { busy: false })
  },
  async load_briefing_canvasses({ dispatch, commit, state }) {
    commit(PROCESSING, { busy: true, message: 'Refreshing previews' })
    const result = await api.briefing_canvasses(state.briefing, { from_cache: false })
    commit(REFRESH_BRIEFING_CANVASSES, { briefing_canvasses: result })
    dispatch(ACTIVATE_SCENE, result[0])
    commit(PROCESSING, { busy: false })
  },
  async load_templates({ commit, state }) {
    commit(PROCESSING, { busy: true, message: 'Retrieving available templates' })
    const templates = await api.get_canvasses(state.briefing)
    commit(INIT_TEMPLATES, { templates })
    commit(PROCESSING, { busy: false })
  },
  async add_design({ dispatch, commit, state }, canvas) {
    const briefing_canvas = await api.create_briefing_canvas(state.briefing, canvas)
    commit(ADD_DESIGN, briefing_canvas)
    dispatch(ACTIVATE_SCENE, briefing_canvas)
    commit(SET_TAB, 'insert')
  },
  async duplicate_design({ commit }, briefing_canvas) {
    const result = await api.dup_briefing_canvas(briefing_canvas)
    commit(ADD_DESIGN, result)
  },
  async delete_design({ commit }, briefing_canvas) {
    commit(DELETE_DESIGN, briefing_canvas)
    await api.delete_briefing_canvas(briefing_canvas)
  },
  async add_assets({ commit, state, dispatch }, assets) {
    await Promise.all(assets.map(async (row) => {
      const result = await api.create_feed_row(state.briefing.feed.complex_identifier, row)
      commit(ADD_ASSET, result.data)
    }))
    commit(PROCESSING, { busy: true, message: 'Loading templates' })
    refresh_canvasses_and_preview(dispatch)
  },
  async delete_asset({ commit, state, dispatch }, asset) {
    await api.delete_feed_row(state.briefing.feed.complex_identifier, asset.adflow_row_id)
    commit(DELETE_ASSET, asset)
    commit(PROCESSING, { busy: true, message: 'Loading templates' })
    refresh_canvasses_and_preview(dispatch)
    // refresh_canvasses_and_preview(dispatch)
  },
  async [DELETE_FEED_ROW]({ commit }, { row, scene }) {
    await api.delete_feed_row(scene.feed.complex_identifier, row.data.adflow_row_id)
    commit(DELETE_FEED_ROW, { row, scene })
  },
  async [ADD_FEED_ROW]({ commit }, { row, scene }) {
    const result = await api.create_feed_row(scene.feed.complex_identifier, { data: row })
    commit(ADD_FEED_ROW, { row: result.data, scene })
  },
  async update_briefing_canvas({ commit }, briefing_canvas) {
    await api.update_briefing_canvas(briefing_canvas)
    commit(UPDATE_BRIEFING_CANVAS, briefing_canvas)
  },
  async toggle_briefing_size({ state, dispatch, commit }, size) {
    const brief = { ...state.briefing }
    if (state.briefing.sizes.includes(size)) {
      brief.sizes = brief.sizes.filter((s) => s !== size)
      await dispatch('update_briefing', brief)
    } else {
      brief.sizes = [...brief.sizes, size]
      await dispatch('update_briefing', brief)
    }
    commit('SET_SIZE', brief.sizes.slice(-1)[0])
    if (state.active_scene === null && state.briefing_canvasses.length > 0) {
      dispatch(ACTIVATE_SCENE, state.briefing_canvasses[0])
    }
  },
  next_scene({ dispatch, state }) {
    const scenes = state.briefing_canvasses
    const index = scenes.findIndex((scene) => scene.id === state.active_scene.id)
    if (index + 1 < scenes.length) {
      dispatch(ACTIVATE_SCENE, scenes[index + 1])
    }
  },
  previous_scene({ dispatch, state }) {
    const scenes = state.briefing_canvasses
    const index = scenes.findIndex((scene) => scene.id === state.active_scene.id)
    if (index - 1 >= 0) {
      dispatch(ACTIVATE_SCENE, scenes[index - 1])
    }
  },
  async update_briefing({ commit, dispatch }, briefing) {
    commit(UPDATE_BRIEFING, briefing)
    update_briefing_remote(dispatch, briefing)
  },
  async update_briefing_remote({ commit, state }, briefing) {
    await api.update_briefing({ ...briefing, id: state.briefing.id })
    commit(UPDATE_BRIEFING, briefing)
  },
  async update_asset({ commit, state }, { row, remote = false }) {
    if (remote === false) {
      commit(UPDATE_ASSET, row)
    } else {
      const result = await api.update_feed_row(state.briefing.feed.complex_identifier, row)
      commit(UPDATE_ASSET, result.data)
      // refresh_canvasses_and_preview(dispatch)
    }
  },
  async process_assets({ dispatch }, footage) {
    footage.forEach((file) => dispatch('process_asset', file))
    // refresh_canvasses_and_preview(dispatch)
  },
  async process_asset({ commit, state, dispatch }, asset) {
    const row = await api.create_feed_row(
      state.briefing.feed.complex_identifier,
      { data: { image_link: null } }
    )
    commit(ADD_ASSET, row.data)
    const file = await api.upload_file(asset, row.data.adflow_row_id)
    dispatch('update_asset', {
      row: {
        data: {
          ...row.data,
          image_link: file.file_url
        }
      },
      remote: true
    })
  },

  [ACTIVATE_SCENE]({ state, commit }, scene) {
    commit(ACTIVATE_SCENE, scene)
    if (state.animation_controller_map[scene.canvas.id]) {
      state.animation_controller_map[scene.canvas.id].reset()
    }
  },

  async build_video({ commit, dispatch, state }, sizes) {
    const result = await api.build_briefing_video({
      ...state.briefing,
    }, sizes)
    commit(SET_BATCH, result)
    listen_to_batch(dispatch, commit, state, 'video', result)
  },

  async build_images({ commit, dispatch, state }, sizes) {
    const result = await api.build_briefing_image({
      ...state.briefing,
    }, sizes)
    commit(SET_BATCH, result)
    listen_to_batch(dispatch, commit, state, 'image', result)
  },
}
