import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class Slider extends Animation {
  constructor({
    samples, uid, layer, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })
  }

  name() {
    return 'Slider'
  }

  cleanup() {
    // Reset the swiper to its start position
    const swiperElement = document.querySelector(`#swiper_${this.uid}_${this.layer.id}`);
    if (swiperElement) {
      swiperElement.style.transform = 'translateX(0px)';
    }

    // Reset any other properties if needed
    // For example, resetting opacity of the first image if it was changed
    const firstImage = document.querySelector(`.swiper__slide__image_${this.uid}_${this.layer.id}`);
    if (firstImage) {
      firstImage.style.opacity = '1';
    }

    const targets = this.targets()
    targets.forEach((target, index) => {
      $(target).css({ zIndex: targets.length - index })
    })
  }

  static int_name() {
    return 'slider'
  }

  static stand_alone() {
    return true
  }

  static exposed_to() {
    return ['slider']
  }

  static properties() {
    return {
      ...super.properties(),
      in_place: {
        pretty_name: 'Static or Animated',
        type: 'List',
        default: 'animated',
        values: [{
          value: 'animated',
          display: 'Animated',
        }, {
          value: 'static',
          display: 'Static',
        }],
      },
      easing: {
        pretty_name: 'Animation type',
        type: 'List',
        default: 'animated',
        values: [{
          value: 'easeInQuad',
          display: 'easeInQuad',
        }, {
          value: 'linear',
          display: 'linear',
        }, {
          value: 'easeOutQuad',
          display: 'easeOutQuad',
        }],
      }
    }
  }

  composeable() {
    return false
  }

  in_place() {
    return (this.conf().in_place === 'static' || this.conf().in_place === true)
  }

  easing() {
    return this.conf().easing || 'easeInQuad'
  }

  has_focus_points(src) {
    try {
      const link = new URL(src)
      const focus_x = link.searchParams.get('focus_x')
      const focus_y = link.searchParams.get('focus_y')
      return !!(focus_x && focus_y)
    } catch (e) {
      return false
    }
  }

  background_position(src) {
    let y = (Math.abs(this.focus_points(src).y) / 2) * 100
    let x = (Math.abs(this.focus_points(src).x) / 2) * 100
    if (this.focus_points(src).y < 0) {
      y += 50
    } else {
      y = 50 - (Math.abs(this.focus_points(src).y) * 50)
    }
    if (this.focus_points(src).x > 0) {
      x += 50
    } else {
      x = 50 - (Math.abs(this.focus_points(src).x) * 50)
    }
    return `${x}% ${y}%`
  }

  focus_points(src) {
    try {
      const link = new URL(src)
      const focus_x = link.searchParams.get('focus_x')
      const focus_y = link.searchParams.get('focus_y')
      return { x: focus_x, y: focus_y }
    } catch (e) {
      return { x: 0, y: 0 }
    }
  }

  conf() {
    return this.layer.config.animations.find((a) => a.type === 'slider')
  }

  componentConf() {
    return this.layer.config['video/slider']
  }

  targets() {
    let assets = []
    assets = Object.values(this.layer.config['video/slider'].images)
    const n = this.componentConf().number_of_images
    return assets.map((_, i) => `#swiper__slide_${this.uid}_${this.layer.id}_${i}`).slice(0, n)
  }

  animation(callback) {
    let timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
    })

    let assets = []
    assets = Object.values(this.layer.config['video/slider'].images).slice(0, this.componentConf().number_of_images)

    if (this.in_place()) {
      assets.forEach((asset, i) => {
        const totalDuration = this.duration;
        const durationPerAsset = totalDuration / (assets.length + 3);
        const firstAssetDelay = durationPerAsset / 3;

        timeline.add({
          targets: `#swiper__slide_${this.uid}_${this.layer.id}_${i}`,
          opacity: [i === 0 ? 1 : 0, 1],
          duration: 1,
          easing: 'linear',
          offset: i === 0 ? firstAssetDelay : 0,
        }).add({
          targets: `#swiper__slide_${this.uid}_${this.layer.id}_${i}`,
          zIndex: i * 10,
          delay: i === 0 ? firstAssetDelay : 0,
          duration: durationPerAsset,
          easing: 'linear',
        })
        if (i !== assets.length - 1) {
          timeline.add({
            targets: `#swiper__slide_${this.uid}_${this.layer.id}_${i}`,
            opacity: [1, 0],
            duration: 1,
            delay: durationPerAsset / 2,
            easing: 'linear',
            offset: `+=${durationPerAsset}`
          });
        }
      });
    } else {
      timeline = this.swipe(timeline, assets, callback)
    }

    return timeline
  }

  swipe(timeline, assets) {
    const duration = this.duration * 0.8;
    // Assuming we want animation to take 40% of the total duration
    const totalAnimationTime = duration * 0.5;
    const animationDurationPerAsset = totalAnimationTime / assets.length;

    // Calculate total time per asset including both animation and display time
    const totalTimePerAsset = (duration / assets.length) - animationDurationPerAsset;

    const firstAssetDelay = totalTimePerAsset / 6;
    const adjustedTotalTimePerAsset = totalTimePerAsset - firstAssetDelay / assets.length;

    assets.forEach((asset, index) => {
      let delay = adjustedTotalTimePerAsset;
      if (index === 0) delay = firstAssetDelay;
      const offset = -Math.abs(index * this.layer.width)
      timeline.add({
        targets: `#swiper_${this.uid}_${this.layer.id}`,
        translateX: offset,
        duration: animationDurationPerAsset,
        delay,
        easing: this.easing(),
      });
    });

    return timeline;
  }
}
