<template>
    <div
        :style="{ color: layer.color, padding: '4%', textAlign: 'align', width: layer.width + 'px', wordBreak: 'normal', whiteSpace: 'normal', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '8%' }">

        <blockquote
            :style="{ fontFamily: font, padding: '0px', margin: '0px', fontSize: (layer.fontSize * .75) + 'px' }"><i>{{
                content
                }}</i></blockquote>

        <div style="display: flex; align-items: center; gap: 4%; margin-top: 6%;">
            <div>
                <div v-if="isImage(image)" :style="{
                    backgroundImage: 'url(' + image + ')',
                    width: `${layer.fontSize * 2.1}px`, // Increased width
                    height: `${layer.fontSize * 2.1}px`, // Increased height
                    borderRadius: '50%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }"></div>
            </div>
            <div style="display: flex; flex-direction: column; gap: 4%;">
                <h2 :style="{ fontSize: (layer.fontSize * .7) + 'px', fontFamily: font }">{{ parse(title) }}</h2>
                <p
                    :style="{ marginTop: '2%', lineHeight: 'normal', fontWeight: 'normal', fontSize: (layer.fontSize * .5) + 'px', fontFamily: font }">
                    {{
                        parse(subtitle) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],
    data() {
        return {

        }
    },
    computed: {
        content() {
            if (this.layer && this.layer.config && this.layer.config.review_card) {
                return this.layer.config.review_card.content
            }
            return ''
        },
        image() {
            return this.layer.config.review_card.image
        },
        rating() {
            return this.layer.config.review_card.rating
        },
        title() {
            return this.layer.config.review_card.title
        },
        subtitle() {
            return this.layer.config.review_card.subtitle
        }
    },
    methods: {
        parse(text) {
            return highlight.highlight(text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color)
        },
        isImage(icon) {
            try {
                const url = new URL(icon);
                return /\.(jpeg|jpg|gif|png|svg|webp)$/.test(url.pathname);
            } catch {
                return false; // Geen geldige URL
            }
        },
    }

}
</script>