import anime from 'animejs/lib/anime';
import Animation from '../animation';

export default class VideoPlayer extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    });
    this.videoElement = null; // Store video element reference
    this.seekThreshold = 0.01; // Define threshold constant
  }

  name() {
    return 'VideoPlayer';
  }

  static stand_alone() {
    return true;
  }

  getVideoElement() {
    // Lazy initialization of the video element
    if (!this.videoElement) {
      this.videoElement = $(`#video_layer_${this.uid}_${this.layer.id}`)[0];
    }
    return this.videoElement;
  }

  seek(dur) {
    const video = this.getVideoElement();
    const time = dur / 1000; // Convert to seconds
    if (video && Number.isFinite(time)) {
      video.pause();
      video.currentTime = time;
    } else {
      console.error('Invalid time value:', time);
    }
  }

  animation() {
    return anime.timeline({
      duration: this.duration,
      autoplay: false,
    });
  }

  async tick(t) {
    const video = this.getVideoElement();
    if (video) {
      const currentTime = t / 1000; // Convert to seconds
      const videoDuration = video.duration;
      const seekTime = currentTime % videoDuration;

      if (window.getChunkedFrame) {
        const base64Frame = await window.getChunkedFrame(this.source(), seekTime)
        this.injectImageFrame(base64Frame);
      } else if (Math.abs(video.currentTime - seekTime) > this.seekThreshold) {
        video.currentTime = seekTime;
        this.monitorSeek(seekTime);
      }

      this.lastUpdateTime = t;
    }
  }

  monitorSeek(seekTime) {
    const video = this.getVideoElement();
    const checkSeek = () => {
      if (Math.abs(video.currentTime - seekTime) < this.seekThreshold) {
        // Successful seek, stop monitoring
      } else {
        // Continue checking until the seek time is reached
        requestAnimationFrame(checkSeek);
      }
    };
    requestAnimationFrame(checkSeek);
  }

  play() {
    const video = this.getVideoElement();
    if (video && video.paused) {
      video.play();
    }
  }

  pause() {
    const video = this.getVideoElement();
    if (video) video.pause();
  }

  cleanup() {
    const video = this.getVideoElement();
    if (video) {
      video.pause();
      video.currentTime = 0;
      video.load();
    }
  }

  restart() {
    this.cleanup();
  }

  prepare() {
    const video = this.getVideoElement();
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
  }

  source() {
    return this.getVideoElement().querySelector('source').src
  }

  injectImageFrame(frame) {
    const video = $(`#video_layer_${this.uid}_${this.layer.id}`);
    $(video).hide();
    const img = $(`#frame_container_${this.uid}_${this.layer.id}`);
    $(img).css('background-image', `url(${frame})`);
    img.show();
  }
}
