<template>
  <div :style="{
    width: layer.width + 'px',
    height: layer.height + 'px',
    position: 'relative',
    backgroundColor: 'none'
  }">
    <!-- Image Stacker -->
    <div :style="stackContainerStyle">
      <!-- Render images dynamically -->
      <div
        v-for="(img, index) in orderedImages"
        :key="index"
        :style="{
          ...imageStackStyle(index),
          backgroundImage: 'url(' + cleanSource(img) + ')',
          backgroundPosition: 'center'
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import FontMixin from '../mixins/font';

export default {
  props: ['layer', 'animation_uid', 'scale'],
  mixins: [FontMixin],
  computed: {
    componentConfig() {
      return this.layer.config.image_stacker;
    },
    images() {
      const config = this.layer.config.image_stacker;
      return Object.keys(config.images)
        .slice(0, this.componentConfig.number_of_images)
        .map((key) => config.images[key]);
    },
    orderedImages() {
      const layout = this.componentConfig.layout;

      if (layout === 'line') {
        // Line layout: normale volgorde
        return this.componentConfig.on_top === 'invert'
          ? [...this.images].reverse()
          : this.images;
      }

      // Podium layout: herverdeel de afbeeldingen
      const reordered = [];
      const totalImages = this.images.length;

      reordered.push(this.images[0]); // Eerste afbeelding in het midden

      for (let i = 1; i < totalImages; i++) {
        if (i % 2 === 1) {
          // Oneven indexen naar links
          reordered.unshift(this.images[i]);
        } else {
          // Even indexen naar rechts
          reordered.push(this.images[i]);
        }
      }

      return reordered;
    },
    stackContainerStyle() {
      return {
        position: 'relative',
        width: '100%',
        height: '100%',
      };
    },
    imageStackStyle() {
      return (index) => {
        const totalImages = this.orderedImages.length;
        const scaleBase = 1 / totalImages;
        const isEven = totalImages % 2 === 0;
        const middleIndex = Math.floor(totalImages / 2);
        const layout = this.componentConfig.layout;

        // Bereken offset
        const gap = this.componentConfig.gap || 0;
        const angle = (this.componentConfig.direction || 0) * (Math.PI / 180); // Hoek in radialen

        // Dynamische schaalberekening
        let scaleAdjustment = layout === 'podium'
          ? Math.max(0.5, 1 - Math.abs(index - middleIndex) * 0.15)
          : 1;

        // Voeg de image_scale waarde toe (0-100)
        const imageScale = (this.componentConfig.image_scale || 100) / 100; // Omrekenen naar factor
        scaleAdjustment *= imageScale;

        const blurAmount = layout === 'podium'
          ? Math.abs(index - middleIndex) * 0.5
          : 0;

        // Bereken z-index
        const zIndex = layout === 'podium'
          ? index === middleIndex
            ? totalImages + 1
            : totalImages - Math.abs(index - middleIndex)
          : totalImages - index;

        // Dynamische breedte en hoogte
        const width = this.layer.width * scaleBase * scaleAdjustment;
        const height = this.layer.height * scaleBase * scaleAdjustment;

        // Bereken visueel midden
        const visualCenterOffset = isEven
          ? (index - middleIndex + 0.5) * gap // Houd rekening met het midden tussen twee afbeeldingen
          : (index - middleIndex) * gap;

        // Offsets met hoek
        const xOffset = visualCenterOffset * Math.cos(angle);
        const yOffset = visualCenterOffset * Math.sin(angle);

        const left = this.layer.width / 2 - width / 2 + xOffset;
        const top = this.layer.height / 2 - height / 2 + yOffset;

        return {
          width: `${width}px`,
          height: `${height}px`,
          position: 'absolute',
          left: `${left}px`,
          top: `${top}px`,
          zIndex: zIndex,
          filter: layout === 'podium' ? `blur(${blurAmount}px)` : 'none', // Blur-effect alleen bij podium
          backgroundSize: this.componentConfig.scale || 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          overflow: 'hidden',
        };
      };
    },
  },
  methods: {
    cleanSource(source) {
      if (source) {
        try {
          const url = new URL(source);
          return url.origin + url.pathname;
        } catch {
          return source;
        }
      }
      return source;
    },
  },
};
</script>