<template>
    <div :style="{ width: layer.width + 'px', height: layer.height + 'px' }">
        <div style="width: 100%; height: 100%; position: relative">
            <!-- Background -->
            <div :style="{
                width: '100%',
                height: '100%',
                left: '0px',
                top: '0px',
                position: 'absolute',
                background: bgColor
            }">
            </div>
            <!-- Dot 1 -->
            <div :style="{
                width: '800px',
                height: '800px',
                left: '0px',
                top: '243px',
                position: 'absolute',
                background: dot1Gradient,
                'border-radius': '9999px'
            }">
            </div>
            <!-- Dot 2 -->
            <div :style="{
                width: '1000px',
                height: '1000px',
                left: '308px',
                top: '508px',
                position: 'absolute',
                background: dot2Gradient,
                'border-radius': '9999px'
            }">
            </div>
            <!-- Blur overlay -->
            <div style="width: 100%; height: 100%; left: 0px; top: 0px; position: absolute; 
                       background: rgba(255, 255, 255, 0.01); 
                       backdrop-filter: blur(100px); -webkit-backdrop-filter: blur(100px)">
            </div>
        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers';
import FontMixin from '../mixins/font';

export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],
    computed: {
        // Background color
        bgColor() {
            return this.layer?.config?.bg_two_dots_blur?.color_1 || '#ffffff'; // Default to white
        },
        // Gradient for dot 1
        dot1Gradient() {
            const color2 = this.layer?.config?.bg_two_dots_blur?.color_2 || 'rgba(248, 218, 26, 0.5)';
            return `radial-gradient(100% 100% at center, ${color2} 0%, rgba(248, 218, 26, 0) 100%)`;
        },
        // Gradient for dot 2
        dot2Gradient() {
            const color3 = this.layer?.config?.bg_two_dots_blur?.color_3 || 'rgba(248, 141, 26, 0.5)';
            return `radial-gradient(100% 100% at center, ${color3} 0%, rgba(248, 141, 26, 0) 100%)`;
        },
    },
};
</script>