import Component from './component'

export default class VideoComponent extends Component {
  constructor(opts = {}, config = {}) {
    super(opts)
    this.config_opts = config
  }

  video() {
    return true
  }
}
