var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ width: _vm.layer.width + 'px', height: _vm.layer.height + 'px' })},[_c('div',{staticStyle:{"width":"100%","height":"100%","position":"relative"}},[_c('div',{style:({
            width: '100%',
            height: '100%',
            left: '0px',
            top: '0px',
            position: 'absolute',
            background: _vm.bgColor
        })}),_vm._v(" "),_c('div',{style:({
            width: '800px',
            height: '800px',
            left: '0px',
            top: '243px',
            position: 'absolute',
            background: _vm.dot1Gradient,
            'border-radius': '9999px'
        })}),_vm._v(" "),_c('div',{style:({
            width: '1000px',
            height: '1000px',
            left: '308px',
            top: '508px',
            position: 'absolute',
            background: _vm.dot2Gradient,
            'border-radius': '9999px'
        })}),_vm._v(" "),_c('div',{staticStyle:{"width":"100%","height":"100%","left":"0px","top":"0px","position":"absolute","background":"rgba(255, 255, 255, 0.01)","backdrop-filter":"blur(100px)","-webkit-backdrop-filter":"blur(100px)"}})])])}
var staticRenderFns = []
export { render, staticRenderFns }