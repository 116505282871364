import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class DropIn extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })
  }

  name() {
    return 'DropIn'
  }

  cleanup() {
    const targets = $(`${this.target()}`)

    // Restore the initial transform state and opacity to keep the original position and opacity
    $(targets).css('transform', `${this.initial_transform} scale(1)`);
    $(targets).css('opacity', '1');
  }

  prepare() {
    const targets = $(`${this.target()}`)

    // Set the initial state for the animation
    $(targets).css('transform', 'translateY(-1200px) scale(0.2)');
    $(targets).css('opacity', '0');
  }

  static int_name() {
    return 'drop'
  }

  static exposed_to() {
    return ['component', 'background', 'slider', 'image', 'text', 'text_area',
      'video', 'price', 'rectangle', 'circle', 'logo', 'review']
  }

  static properties() {
    const props = {
      ...super.properties(),
    }
    delete props.easing
    return props
  }

  animation_config(duration = this.duration) {
    return {
      targets: this.target(),
      translateY: [
        { value: -1200, duration: 0 },
        { value: 0, duration: duration * 0.66, easing: 'easeOutBounce' }
      ],
      opacity: {
        value: [0, 1],
        duration: duration * 0.33,
        easing: 'easeOutQuad'
      },
      scale: {
        value: [0.2, 1],
        duration: duration * 0.66,
        easing: 'easeOutBack'
      },
    }
  }

  animation() {
    return anime.timeline({
      duration: this.duration,
      autoplay: false,
    }).add(this.animation_config())
  }
}
