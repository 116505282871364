import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ReviewStars extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Review stars'
  }

  labels() {
    return ['review']
  }

  icon() {
    return 'fa-solid fa-star'
  }

  baseConfig() {
    return {
      layer_type: 'review',
      text: '9',
      color: '#F8E71C',
      align: 'center',
      fontSize: 20,
      width: 190,
      height: 60,
      ...this.opts,
      config: {
        v_align: 'center',
        product_score_limit: 5,
        product_score_show_n_reviews: false,
        product_score_n_reviews: 192,
        product_score_base: 10,
        show_animations: true,
        animation: {
          delay_percentage: 0,
          duration_percentage: 100,
          in: null,
          while: null,
          out: null,
        },
        animations: [
          {
            type: 'inwhileout'
          },
          {
            type: 'review_stars_fly_in',
            easing: 'easeInOutExpo'
          }],
      }
    }
  }

  config() {
    return {
      background: 'white',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
