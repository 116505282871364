<template>
  <span v-if="canvas && internal_size" style="width: 100%; height: 100%">
    <div ref="parentContainer" class="canvas_scale_container">
      <div :style="{ zoom: computedScale, width: `${internal_size.w}px`, height: `${internal_size.h}px` }">
        <Canvas :size="{ w: internal_size.w, h: internal_size.h }" :layers="layers"
          :background="canvas['background_image']" :background_color="canvas.background_color"
          :brandkit="brandkit || {}" :disabled="true" :animation_uid="animation_uid()" :samples="l_samples"
          :on_hover_play="on_hover_play" ref="canvas" />
      </div>
    </div>
  </span>
  <span v-else>
    <div class="placeholder-container">
      <!--<img src="/adflow-logo.svg" class="placeholder-image" />-->
      <div class="loading-overlay"></div>
    </div>
  </span>
</template>

<script>
import axios from 'axios';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
import Canvas from './canvas.vue';

export default {
  components: { Canvas },
  props: {
    classes: {
      type: String,
      required: false,
      default: 'design__scaler'
    },
    on_hover_play: {
      type: Boolean,
      required: false,
      default: false
    },
    samples: {
      type: Object,
      required: false,
      default: () => { return { data: [], indice: 0 } }
    },
    brandkit: {
      type: Object,
      required: true
    },
    canvas_id: {
      type: Number,
      required: true
    },
    size: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      canvas: null,
      l_samples: this.samples,
      parentWidth: 0,
      parentHeight: 0
    };
  },
  computed: {
    internal_size() {
      if (this.size) {
        return {
          w: this.size.display_dimensions[0],
          h: this.size.display_dimensions[1]
        }
      } else if (this.canvas) {
        return {
          w: this.canvas.width,
          h: this.canvas.height
        };
      }
      return { w: 0, h: 0 };
    },
    layers() {
      if (this.size) {
        const variant = this.canvas.variants.find(variant => {
          if (variant.size.display_dimensions[0] === this.size.display_dimensions[0] && variant.size.display_dimensions[1] === this.size.display_dimensions[1]) {
            return true
          }
          return false
        })
        return variant.layers
      } else {
        return this.canvas.layers
      }
    },
    computedScale() {
      const scaleX = this.parentWidth / this.internal_size.w;
      const scaleY = this.parentHeight / this.internal_size.h;
      return Math.min(scaleX, scaleY);
    }
  },
  watch: {
    canvas: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.updateParentDimensions();
          });
        }
      }
    }
  },
  methods: {
    updateParentDimensions() {
      if (this.canvas && this.$refs.parentContainer) {
        const parent = this.$refs.parentContainer;
        this.parentWidth = parent.clientWidth;
        this.parentHeight = parent.clientHeight;
      }
    },
    animation_uid() {
      return (Math.random() + 1).toString(36).substring(7);
    }
  },
  async mounted() {
    const result = await axios.get(`/image_canvasses/${this.canvas_id}.json`);
    this.canvas = result.data;

    if (this.canvas.feed && this.canvas.feed.data.length > 0 && this.samples.data.length === 0) {
      this.l_samples = { data: this.canvas.feed.data.map(d => d.data), indice: 0 };
    }
    window.addEventListener('resize', this.updateParentDimensions);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateParentDimensions);
  }
};
</script>