import anime from 'animejs/lib/anime'
import Animation from '../../animation'

export default class Slide extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })

    this.initial_styles = {
      transform: this.layer.config.transform || `translate(${0}px, ${0}px) scale(1)`,
      opacity: ''
    }
  }

  name() {
    return 'Slide'
  }

  cleanup() {
    const { transform } = this.initial_styles
    $(`${this.target()}`).css('transform', transform)
    $(`${this.target()}`).css('opacity', this.opacity())
  }

  static pretty_name() {
    return 'Slide';
  }

  prepare() {
    const directionMultiplier = this.conf().direction ? -100 : 100;
    $(`${this.target()}`).css('transform', `translateX(${directionMultiplier}%)`)
    $(`${this.target()}`).css('opacity', `${this.conf().direction ? 0 : this.opacity()}`)
  }

  static int_name() {
    return 'composeable_slide'
  }

  static exposed_to() {
    return ['component', 'background', 'image', 'logo', 'slider', 'review', 'cube_spinner',
      'text', 'text_area', 'video', 'price', 'rectangle', 'circle']
  }

  static properties() {
    const props = {
      ...super.properties(),
      direction: {
        type: 'List',
        default: 'left',
        values: [{
          value: 'left',
          display: 'Left'
        }, {
          value: 'right',
          display: 'Right'
        }, {
          value: 'top',
          display: 'Top'
        }, {
          value: 'bottom',
          display: 'Bottom'
        }],
      }
    }
    delete props.easing
    return props
  }

  animation_config(duration = this.duration, in_out = 'in') {
    let translateX = ['0%', '0%']
    let translateY = ['0%', '0%']
    let opacity = [0, this.opacity()]
    let easing = 'easeOutQuad'

    const direction = this.layer.config.animation[in_out].direction || 'left'

    if (in_out === 'in') {
      switch (direction) {
        case 'left':
          translateX = ['-100%', '0%']
          break
        case 'right':
          translateX = ['100%', '0%']
          break
        case 'top':
          translateY = ['-100%', '0%']
          break
        case 'bottom':
          translateY = ['100%', '0%']
          break
        default:
          translateX = ['-100%', '0%']
          break
      }
    } else {
      switch (direction) {
        case 'left':
          translateX = ['0%', '-100%']
          break
        case 'right':
          translateX = ['0%', '100%']
          break
        case 'top':
          translateY = ['0%', '-100%']
          break
        case 'bottom':
          translateY = ['0%', '100%']
          break
        default:
          translateX = ['0%', '-100%']
          break
      }
      opacity = [this.opacity(), 0]
      easing = 'easeInQuad'
    }

    // Apply speedFactor to duration
    const adjustedDuration = duration

    const anim_conf = {
      targets: `${this.target()}`,
      translateX,
      translateY,
      opacity,
      easing,
      duration: adjustedDuration, // Use the adjusted duration
      loop: false,
    }
    return anim_conf
  }

  animation() {
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
      complete: () => {
        this.finish()
      }
    })

    timeline.add(this.animation_config())

    return timeline
  }
}
